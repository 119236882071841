import { GalleryTypeCreateContainer } from '@app/feautres/gallery-type/containers/gallery-type-create';
import { ModalTrigger } from '@app/components/modal';
import { Button } from '@app/components/button';
import { PageLayout } from '@app/panel/layout/page';
import { GalleryTypes } from '@app/feautres/gallery-type/containers/gallery-types';

export const GalleryTypesPage = () => {
  return (
    <PageLayout
      title="Gallery types"
      right={
        <ModalTrigger content={(close) => <GalleryTypeCreateContainer onCreated={close} />}>
          {(trigger) => (
            <Button icon="add" onClick={trigger}>
              Create
            </Button>
          )}
        </ModalTrigger>
      }
    >
      <GalleryTypes />
    </PageLayout>
  );
};

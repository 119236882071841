import styled, { css } from 'styled-components';
import { breakpointsMedia, BreakpointType } from '@app/styles/breakpoints';

interface ColumnsProps {
  breakpoint?: BreakpointType;
}

export const Columns = styled.div.withConfig({
  shouldForwardProp: (props) => !['breakpoint'].includes(props),
})<ColumnsProps>`
  display: grid;
  gap: 20px;

  ${({ breakpoint = 'xl' }) => css`
    ${breakpointsMedia[breakpoint]} {
      gap: 50px;
      grid-template-columns: 1fr 1fr;
    }
  `}
`;

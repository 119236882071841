import { FC } from 'react';
import { Control, useController } from 'react-hook-form';
import { FormField } from '@app/layout/form-field';
import { GalleryConfigFields } from '../gallery-config-resolver';
import { UserSelect } from '@app/feautres/users/containers/user-select';
import { Role } from '@app/__generated__/graphql';

interface EventManagerFormFieldProps {
  control: Control<GalleryConfigFields>;
}

export const EventManagerFormField: FC<EventManagerFormFieldProps> = ({ control }) => {
  const {
    fieldState: { error },
    field: { onChange, onBlur, value },
  } = useController({ control, name: 'gallery.eventManagerId' });

  const handleChange = (id: string | null) => {
    onChange(id);
    onBlur();
  };

  return (
    <div>
      <FormField title="Event manager" error={error}>
        <UserSelect role={Role.EventManager} onChange={handleChange} value={value} isError={!!error} />
      </FormField>
    </div>
  );
};

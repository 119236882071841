import { useNavigate } from 'react-router';
import { GalleryBaseFormView } from '@app/feautres/gallery/views/gallery-base-form';
import { GalleryConfigFields } from '@app/feautres/gallery/views/gallery-base-form/gallery-config-resolver';
import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { useGalleryId } from '@app/feautres/gallery/contexts/gallery-id.context';
import { graphql } from '@app/__generated__';
import { useMutation, useSuspenseQuery } from '@apollo/client';

const galleryConfigDocument = graphql(`
  query galleryConfig($galleryId: UUID!) {
    gallery(galleryId: $galleryId) {
      id
      name
      slug
      thumbnailPosition
      startsAt
      endsAt
      galleryTypeId
      eventManagerId
      techniciansIds
      contacts
    }
    statsConfig(galleryId: $galleryId) {
      isPeopleCounterActive
    }
  }
`);

const updateGalleryDocument = graphql(`
  mutation updateGallery($galleryId: UUID!, $gallery: UpdateGalleryInput!, $statsConfig: SaveStatsConfigInput!) {
    updateGallery(galleryId: $galleryId, data: $gallery) {
      id
      name
    }
    saveStatsConfig(galleryId: $galleryId, data: $statsConfig)
  }
`);

export const GalleryBaseEditSubpage = () => {
  const galleryId = useGalleryId();
  const { data } = useSuspenseQuery(galleryConfigDocument, {
    variables: { galleryId },
    fetchPolicy: 'network-only',
  });

  const navigate = useNavigate();
  const [update, { loading }] = useMutation(updateGalleryDocument, {
    onCompleted: () => navigate('../../'),
    onError: getErrorHandler(),
  });

  const handleSubmit = (config: GalleryConfigFields) => {
    update({ variables: { galleryId, ...config } });
  };

  return <GalleryBaseFormView data={data} onSubmit={handleSubmit} loading={loading} />;
};

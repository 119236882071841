import { createBrowserRouter } from 'react-router-dom';
import { Navigate } from 'react-router';
import { ROUTES } from '../constants/routes.constant';
import { ProtectedPanel } from './protected-panel';
import { SignInPage } from '../pages/signin';
import { galleriesRoutes } from './routes/galleries';
import { capturesRoutes } from './routes/captures';
import { messagesRoutes } from './routes/messages';
import { galleryTypesRoutes } from './routes/gallery-types';
import { profileRoutes } from './routes/profile';
import { RouterBoundary } from '@app/components/boundary';
import { StatusPage } from '../pages/status';

export const router = createBrowserRouter([
  {
    element: <RouterBoundary />,
    children: [
      {
        path: ROUTES.signin,
        element: <SignInPage />,
      },
      {
        element: <ProtectedPanel />,
        children: [
          galleriesRoutes,
          capturesRoutes,
          messagesRoutes,
          galleryTypesRoutes,
          profileRoutes,
          { path: 'status', element: <StatusPage /> },
        ],
      },
      {
        path: '*?',
        element: <Navigate to={ROUTES.galleries} replace />,
      },
    ],
  },
]);

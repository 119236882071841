import { useNavigate } from 'react-router';
import { GalleryConfigFields } from '@app/feautres/gallery/views/gallery-base-form/gallery-config-resolver';
import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { graphql } from '@app/__generated__';
import { useMutation } from '@apollo/client';
import { PageLayout } from '@app/panel/layout/page';
import { GalleryBaseFormView } from '@app/feautres/gallery/views/gallery-base-form';
import { useCurrentUser } from '@app/panel/hooks/use-current-user';
import { Role } from '@app/__generated__/graphql';
import { useMemo } from 'react';

const createGalleryDocument = graphql(`
  mutation createGallery($data: CreateGalleryInput!) {
    gallery: createGallery(data: $data) {
      id
    }
  }
`);

const saveStatsConfigDocument = graphql(`
  mutation saveStatsConfig($galleryId: UUID!, $data: SaveStatsConfigInput!) {
    saveStatsConfig(galleryId: $galleryId, data: $data)
  }
`);

export const CreateGalleryPage = () => {
  const navigate = useNavigate();
  const [create, { loading }] = useMutation(createGalleryDocument, {
    onError: getErrorHandler({ UNIQUE_ENTITY_ERROR: 'Gallery slug already exists' }),
  });

  const [saveStatsConfig, { loading: statsConfigLoading }] = useMutation(saveStatsConfigDocument, { onError: getErrorHandler() });

  const handleSubmit = async (galleryConfig: GalleryConfigFields) => {
    const { data } = await create({ variables: { data: { ...galleryConfig.gallery } } });
    if (!data) return;
    await saveStatsConfig({ variables: { galleryId: data.gallery.id, data: galleryConfig.statsConfig } });
    navigate(`../${data.gallery.id}/template`, { replace: true });
  };

  const currentUser = useCurrentUser();
  const defaultValues = useMemo(() => {
    if (currentUser.role !== Role.EventManager) return;
    return { gallery: { eventManagerId: currentUser.id } };
  }, [currentUser]);

  return (
    <PageLayout title="Create gallery">
      <GalleryBaseFormView onSubmit={handleSubmit} loading={loading || statsConfigLoading} data={defaultValues} isCreate />
    </PageLayout>
  );
};

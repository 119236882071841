import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const ProtectedPanelWrapper = styled.div`
  padding-bottom: 60px;

  ${breakpointsMedia.md} {
    padding-bottom: 0;
    padding-left: 60px;
  }
`;

import { useForm, FormProvider, DefaultValues } from 'react-hook-form';
import { FC } from 'react';
import { Flex } from '@app/layout/flex';
import { Button } from '@app/components/button';
import { templateResolver, TemplateFields } from './template-resolver';
import { LayoutItem } from '@app/layout/layout-item';
import { Text } from '@app/layout/text';
import { EmailPreview } from './email-preview';
import { Columns } from '@app/layout/columns';
import { SmsPreview } from './sms-preview';
import { QrLandingPreview } from './qr-landing-preview';
import { EmailTemplateFormPart } from './email-template';
import { EmailSenderFormPart } from './email-sender';
import { SmsFormPart } from './sms';
import { QrFormPart } from './qr';
import { Boundary } from '@app/components/boundary';

interface TemplateFormProps {
  data: DefaultValues<TemplateFields>;
  onSubmit: (data: TemplateFields) => void;
  loading: boolean;
}

export const TemplateFormView: FC<TemplateFormProps> = ({ data, onSubmit, loading }) => {
  const methods = useForm<TemplateFields>({
    resolver: templateResolver,
    mode: 'onChange',
    defaultValues: data,
  });
  const { handleSubmit, formState, watch } = methods;

  const values = watch();

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex gap={70}>
          <Flex>
            <Text>Email config</Text>
            <EmailSenderFormPart />
            <Columns>
              <EmailTemplateFormPart />
              <Boundary>
                <EmailPreview template={values} shouldRender={formState.isValid} />
              </Boundary>
            </Columns>
          </Flex>
          <Flex>
            <Text>Sms config</Text>
            <Columns>
              <SmsFormPart />
              <SmsPreview template={values} />
            </Columns>
          </Flex>
          <Flex>
            <Text>QR config</Text>
            <Columns>
              <QrFormPart />
              <QrLandingPreview template={values} shouldRender={formState.isValid} />
            </Columns>
          </Flex>
          <LayoutItem>
            <Button icon="save" type="submit" isLoading={loading}>
              Save
            </Button>
          </LayoutItem>
        </Flex>
      </form>
    </FormProvider>
  );
};

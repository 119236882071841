import { Flex } from '@app/layout/flex';
import { StatusItem, StatusLabel, StatusValue } from './gallery-status-item.styles';
import { Icon } from '@app/components/icon';
import { Color } from '@app/styled';
import { FC } from 'react';

interface GalleryStatusItemProps {
  url: string;
  color: Color;
  label: string;
  value: number;
  ttId: string;
  ttContent: string;
}

export const GalleryStatusItem: FC<GalleryStatusItemProps> = ({ url, color, label, value, ttId, ttContent }) => {
  return (
    <Flex alignItems="center" gap={5}>
      <StatusItem to={url}>
        <StatusLabel color={color}>{label}</StatusLabel>
        <StatusValue color={color}>{value}</StatusValue>
      </StatusItem>
      <div style={{ padding: 10 }} data-tooltip-id={ttId} data-tooltip-content={ttContent}>
        <Icon name="question" size={13} />
      </div>
    </Flex>
  );
};

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@app/panel/constants/routes.constant';
import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { FC, ReactNode } from 'react';
import { useGalleryId } from '@app/feautres/gallery/contexts/gallery-id.context';
import { graphql } from '@app/__generated__';
import { useMutation } from '@apollo/client';

const duplicateGalleryDocument = graphql(`
  mutation duplicateGallery($galleryId: UUID!) {
    newGallery: duplicateGallery(galleryId: $galleryId) {
      id
    }
  }
`);

interface DuplicateGalleryProps {
  children: (data: { duplicate: () => void; loading: boolean }) => ReactNode;
}

export const DuplicateGallery: FC<DuplicateGalleryProps> = ({ children }) => {
  const navigate = useNavigate();
  const galleryId = useGalleryId();

  const [duplicate, { loading }] = useMutation(duplicateGalleryDocument, {
    variables: { galleryId },
    onCompleted: ({ newGallery }) => navigate(`/${ROUTES.galleries}/${newGallery.id}/config`),
    onError: getErrorHandler({ UNIQUE_ENTITY_ERROR: 'Gallery is already duplicated' }),
  });

  return children({ duplicate, loading });
};

import { ROUTES } from '../../../constants/routes.constant';
import { SidebarLogout, SidebarWrapper } from './sidebar.styles';
import { Responsive } from '@app/components/responsive';
import { Icon } from '@app/components/icon';
import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { graphql } from '@app/__generated__';
import { useMutation } from '@apollo/client';
import { CurrentUserDocument } from '@app/__generated__/graphql';
import { SidebarLink } from './link';
import { Flex } from '@app/layout/flex';
import { useState } from 'react';

const userLogoutDocument = graphql(`
  mutation userLogout {
    userLogout
  }
`);

export const Sidebar = () => {
  const [logout] = useMutation(userLogoutDocument, {
    refetchQueries: [CurrentUserDocument],
    context: { auth: true },
    onError: getErrorHandler(),
  });

  const [isOpen, setIsOpen] = useState(false);

  return (
    <SidebarWrapper $open={isOpen}>
      <SidebarLink icon="galleries" to={ROUTES.galleries} />
      <SidebarLink icon="captures" to={ROUTES.captures} />
      <Responsive target="mobile">
        <Flex alignItems="center" justifyContent="center" onClick={() => setIsOpen(!isOpen)}>
          <Icon name="details" color="white" size={20} />
        </Flex>
      </Responsive>
      <SidebarLink icon="messages" to={ROUTES.messages} />
      <SidebarLink icon="stats" to={ROUTES.status} />
      <SidebarLink icon="galleryType" to={ROUTES.galleryTypes} />
      <Responsive target="desktop">
        <SidebarLogout onClick={() => logout()}>
          <Icon name="logout" color="white" size={30} />
        </SidebarLogout>
      </Responsive>
    </SidebarWrapper>
  );
};

import { useGalleryId } from '@app/feautres/gallery/contexts/gallery-id.context';
import { Stats } from '@app/feautres/stats/containers/stats';
import { GalleryStatsSubpageWrapper } from './gallery-stats-subpage.styles';

export const GalleryStatsSubpage = () => {
  const galleryId = useGalleryId();
  return (
    <GalleryStatsSubpageWrapper>
      <Stats galleryId={galleryId} />
    </GalleryStatsSubpageWrapper>
  );
};

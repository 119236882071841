import { FC, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { FormField } from '@app/layout/form-field';
import { GalleryConfigFields } from '../gallery-config-resolver';
import { Button } from '@app/components/button';
import { Input } from '@app/components/input';
import { Text } from '@app/layout/text';
import { Flex } from '@app/layout/flex';
import * as z from 'zod';

interface EventManagerFormFieldProps {
  control: Control<GalleryConfigFields>;
}

export const ContactsFormField: FC<EventManagerFormFieldProps> = ({ control }) => {
  const {
    fieldState: { error },
    field: { onChange, value },
  } = useController({ control, name: 'gallery.contacts' });

  const [newValue, setNewValue] = useState('');

  const handleAdd = () => {
    const result = z.string().email().safeParse(newValue);
    if (!result.success) return;
    if (!value.includes(newValue)) onChange([...value, newValue]);
    setNewValue('');
  };

  const handleRemove = (contact: string) => {
    onChange(value.filter((x) => x !== contact));
  };

  return (
    <div style={{ display: 'none' }}>
      <Flex gap={5}>
        <FormField
          title="Contacts"
          error={error}
          right={
            <Button type="button" size="small" onClick={handleAdd}>
              Add
            </Button>
          }
        >
          <Input value={newValue} onChange={(e) => setNewValue(e.target.value)} />
        </FormField>
        {value.map((contact, i) => (
          <Flex key={i} alignItems="center" row>
            <Text size="xs">{contact}</Text>
            <Button size="small" type="button" onClick={() => handleRemove(contact)}>
              X
            </Button>
          </Flex>
        ))}
      </Flex>
    </div>
  );
};

import { FC, useEffect, useState } from 'react';
import { UploadArea } from '@app/components/upload-area';
import { useGalleryId } from '../../contexts/gallery-id.context';
import { toast } from 'react-toastify';
import { uploadGalleryAsset } from '@app/api';

interface AssetSelectorProps {
  onChange: (fileUrl: string) => void;
}

export const AssetSelector: FC<AssetSelectorProps> = ({ onChange }) => {
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const galleryId = useGalleryId();

  const handleSelect = (file: File) => setFileToUpload(file);

  useEffect(() => {
    if (!fileToUpload) return;
    setIsUploading(true);
    const controller = new AbortController();

    uploadGalleryAsset({ galleryId, file: fileToUpload }, controller.signal)
      .then((url) => {
        onChange(url);
        setFileToUpload(null);
      })
      .catch(() => toast.error('Error uploading file'))
      .finally(() => setIsUploading(false));

    return () => controller.abort();
  }, [fileToUpload, galleryId, onChange]);

  return <UploadArea onSelect={handleSelect} isUploading={isUploading} />;
};

import ReactSelect, { GroupBase, Props as OriginalProps } from 'react-select';
import { useTheme } from 'styled-components';
import { useBreakpoint } from '@app/hooks/use-breakpoint';

interface SelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>
  extends OriginalProps<Option, IsMulti, Group> {
  isError?: boolean;
}

export const Select = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
  isError,
  ...props
}: SelectProps<Option, IsMulti, Group>) => {
  const theme = useTheme();
  const isDesktop = useBreakpoint('md');
  return (
    <ReactSelect
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          borderColor: isError ? theme.colors.danger : theme.colors.gray,
          height: '41px',
          borderRadius: '0',
          borderTop: '0',
          borderLeft: '0',
          borderRight: '0',
          fontFamily: "'aktiv-grotesk', sans-serif",
          fontSize: isDesktop ? '14px' : '16px',
        }),
      }}
      {...props}
    />
  );
};

import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { TemplateFields } from '../template-resolver';
import { Columns } from '@app/layout/columns';
import { FormField } from '@app/layout/form-field';
import { Input } from '@app/components/input';

export const EmailSenderFormPart: FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TemplateFields>();

  return (
    <Columns>
      <FormField title="Subject" error={errors.email?.subject}>
        <Input isError={!!errors.email?.subject} {...register('email.subject')} />
      </FormField>
      <FormField title="From" error={errors.email?.fromName}>
        <Input isError={!!errors.email?.fromName} {...register('email.fromName')} />
      </FormField>
    </Columns>
  );
};

import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const GallerySummarySubpageGrid = styled.div`
  display: grid;
  gap: 50px;

  ${breakpointsMedia.lg} {
    grid-template-columns: 1fr 1fr;
  }
`;

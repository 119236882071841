import { FC } from 'react';
import { InfoGroup } from '@app/layout/info-group';
import { Flex } from '@app/layout/flex';
import { Text } from '@app/layout/text';
import { Columns } from '@app/layout/columns';
import { formatDatetime } from '@app/utils/format.util';
import { CapturePreview } from '@app/feautres/capture/components/capture-preview';
import { useBreakpoint } from '@app/hooks/use-breakpoint';
import { ViewCaptureDetails } from './interfaces/view-capture-details.interface';
import { CaptureMessages } from '../../../message/containers/capture-messages';
import { CaptureDetailsViewPreview } from './capture-details-view.styles';

interface CaptureDetailsViewProps {
  capture: ViewCaptureDetails;
}

export const CaptureDetailsView: FC<CaptureDetailsViewProps> = ({ capture }) => {
  const isDesktop = useBreakpoint('md');

  return (
    <Flex gap={60}>
      <Text size="lg">Capture details</Text>
      <CaptureDetailsViewPreview>
        <CapturePreview capture={capture} maxWidth={isDesktop ? 530 : 300} maxHeight={isDesktop ? 350 : 200} />
      </CaptureDetailsViewPreview>
      <Columns breakpoint="md">
        <InfoGroup header="Created at" value={formatDatetime(capture.createdAt)} />
        <InfoGroup header="Upload filename" value={capture.uploadFilename} />
      </Columns>
      <Flex>
        <Text size="lg" center>
          Messages
        </Text>
        <CaptureMessages captureId={capture.id} />
      </Flex>
    </Flex>
  );
};

import { Text } from '@app/layout/text';
import { breakpointsMedia } from '@app/styles/breakpoints';
import styled from 'styled-components';

export const GalleryStatusWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grayLight};
  display: flex;
  gap: 20px;

  flex-direction: column;
  padding: 10px;

  ${breakpointsMedia.md} {
    padding: 30px;
  }
`;

export const StatusThumb = styled.img`
  width: 80px;
  cursor: pointer;

  ${breakpointsMedia.md} {
    width: 140px;
  }
`;

export const StatusCategory = styled(Text)`
  position: relative;
  font-size: ${({ theme }) => theme.fontSizes.xs};

  ${breakpointsMedia.md} {
    font-size: ${({ theme }) => theme.fontSizes.base};
  }

  &::after {
    content: '';
    position: absolute;
    width: 200px;
    height: 1px;
    background: ${({ theme }) => theme.colors.primary};
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const StatusGrid = styled.div<{ $count: number }>`
  display: grid;
  grid-template-columns: repeat(${({ $count }) => $count}, 1fr);
`;

import { Icon, IconName } from '@app/components/icon';
import { SidebarLinkWrapper } from './sidebar-link.styles';

interface SidebarLinkProps {
  to: string;
  icon: IconName;
}

export const SidebarLink = ({ to, icon }: SidebarLinkProps) => (
  <SidebarLinkWrapper to={to}>
    <Icon name={icon} color="white" size={25} />
  </SidebarLinkWrapper>
);

import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TemplateFields } from '../template-resolver';
import { Checkbox } from '@app/components/checkbox';
import { Flex } from '@app/layout/flex';
import { FormField } from '@app/layout/form-field';
import { AssetSelector } from '@app/feautres/gallery/containers/asset-selector';
import { Text } from '@app/layout/text';
import { Input, Textarea } from '@app/components/input';
import { Columns } from '@app/layout/columns';
import { ModalTrigger } from '@app/components/modal';
import { CustomMjml } from './custom-mjml';

export const EmailTemplateFormPart: FC = () => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext<TemplateFields>();

  const logoWidth = watch('email.logoWidth');

  return (
    <Flex>
      <FormField title="Logo">
        <div>
          <AssetSelector onChange={(fileUrl) => setValue('email.logoUrl', fileUrl)} />
          <Flex row alignItems="center">
            <Text center>{logoWidth}px</Text>
            <input
              style={{ width: '100%' }}
              type="range"
              {...register('email.logoWidth', { valueAsNumber: true })}
              min="50"
              max="450"
            />
            <Controller
              control={control}
              name="email.logoPadding"
              render={({ field: { onChange, value } }) => (
                <Checkbox checked={value} onChange={onChange}>
                  Padding
                </Checkbox>
              )}
            />
          </Flex>
        </div>
      </FormField>
      <FormField title="Header" error={errors.email?.headerText}>
        <Textarea isError={!!errors.email?.headerText} {...register('email.headerText')} />
      </FormField>
      <FormField title="Top text" error={errors.email?.topText}>
        <Textarea isError={!!errors.email?.topText} {...register('email.topText')} />
      </FormField>
      <FormField title="Button text" error={errors.email?.buttonText}>
        <Input isError={!!errors.email?.buttonText} {...register('email.buttonText')} />
      </FormField>
      <FormField title="Bottom text" error={errors.email?.bottomText}>
        <Textarea isError={!!errors.email?.bottomText} {...register('email.bottomText')} />
      </FormField>
      <Columns breakpoint="md">
        <FormField title="Text color" error={errors.email?.textColor}>
          <Input isError={!!errors.email?.textColor} {...register('email.textColor')} />
        </FormField>
        <FormField title="Color" error={errors.email?.color}>
          <Input isError={!!errors.email?.color} {...register('email.color')} />
        </FormField>
      </Columns>
      <Columns breakpoint="md">
        <FormField title="Background color" error={errors.email?.bgColor}>
          <Input isError={!!errors.email?.bgColor} {...register('email.bgColor')} />
        </FormField>
        <FormField title="Content background color" error={errors.email?.contentBgColor}>
          <Input isError={!!errors.email?.contentBgColor} {...register('email.contentBgColor')} />
        </FormField>
      </Columns>
      <Controller
        control={control}
        name="email.showBottomImg"
        render={({ field: { onChange, value } }) => (
          <Checkbox checked={value} onChange={onChange}>
            Powered by PICSANE
          </Checkbox>
        )}
      />
      <ModalTrigger content={(close) => <CustomMjml onSubmit={close} />}>
        {(trigger) => (
          <Text color="white" onDoubleClick={trigger}>
            .
          </Text>
        )}
      </ModalTrigger>
    </Flex>
  );
};

import { DateRange, RangeKeyDict } from 'react-date-range';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import dayjs from 'dayjs';
import { useBreakpoint } from '@app/hooks/use-breakpoint';

interface RangeCalendarProps<T extends FieldValues> {
  control: Control<T>;
  startName: Path<T>;
  endName: Path<T>;
}

export const RangeCalendar = <T extends FieldValues>({ control, startName, endName }: RangeCalendarProps<T>) => {
  const {
    field: { onChange: onStartChange, value: startValue },
  } = useController({ control, name: startName });

  const {
    field: { onChange: onEndChange, value: endValue },
  } = useController({ control, name: endName });

  const handleChange = ({ selection }: RangeKeyDict) => {
    const { startDate, endDate } = selection;
    if (startDate) onStartChange(startDate.toISOString());
    if (endDate) onEndChange(dayjs(endDate).add(1, 'day').subtract(1, 'minute').toISOString());
  };

  const range = {
    startDate: new Date(startValue),
    endDate: dayjs(endValue).subtract(1, 'day').add(1, 'minute').toDate(),
    key: 'selection',
  };

  const isDesktop = useBreakpoint('md');
  return (
    <DateRange
      editableDateInputs={true}
      onChange={handleChange}
      moveRangeOnFirstSelection={false}
      ranges={[range]}
      months={isDesktop ? 2 : 1}
      direction="horizontal"
    />
  );
};

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@app/panel/constants/routes.constant';
import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { FC, ReactNode } from 'react';
import { useGalleryId } from '@app/feautres/gallery/contexts/gallery-id.context';
import { graphql } from '@app/__generated__';
import { useMutation } from '@apollo/client';
import { useConfirm } from '@app/feautres/confirm/context/confirm-context';

const deleteGalleryDocument = graphql(`
  mutation deleteGallery($galleryId: UUID!) {
    deleteGallery(galleryId: $galleryId)
  }
`);

interface RemoveGalleryProps {
  children: (data: { remove: () => void; loading: boolean }) => ReactNode;
}

export const RemoveGallery: FC<RemoveGalleryProps> = ({ children }) => {
  const navigate = useNavigate();
  const galleryId = useGalleryId();

  const [deleteGallery, { loading }] = useMutation(deleteGalleryDocument, {
    variables: { galleryId },
    onCompleted: () => navigate(`/${ROUTES.galleries}`),
    onError: getErrorHandler(),
  });

  const remove = useConfirm({
    question: `Are you sure you want to delete gallery?`,
    actionName: 'Delete',
    cb: deleteGallery,
  });

  return children({ remove, loading });
};

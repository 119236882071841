import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  createHttpLink,
  from,
  fromPromise,
  InMemoryCache,
  split,
  toPromise,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getAccessToken, refresh, setUserToken } from '../api/user-auth.service';
import { httpLink } from './links/http-link';
import { getErrorCode } from '@app/utils/apollo-errors.util';
import { API_GRAPHQL_AUTH_URL } from '@app/constants/config.constant';

const cache = new InMemoryCache();

export const httpAuthLink = createHttpLink({ uri: API_GRAPHQL_AUTH_URL, credentials: 'include' });

const setTokenLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response?.data?.userSignin?.accessToken) {
      setUserToken(response.data.userSignin.accessToken);
    }
    return response;
  });
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getAccessToken();
  if (!token) return { isRefreshed: true };
  return { headers: { ...headers, authorization: `Bearer ${token}` } };
});

const refreshLink = onError(({ graphQLErrors, operation, forward }) => {
  if (operation.getContext().isRefreshed) return;
  if (getErrorCode({ graphQLErrors }) !== 'UNAUTHORIZED') return;

  const refreshOrLogout = () =>
    refresh()
      .then(() => toPromise(forward(operation)))
      .catch(async (err: ApolloError) => {
        const isUnauthorized = getErrorCode(err) === 'UNAUTHORIZED';
        const isCurrentUserQuery = operation.operationName === 'currentUser';
        if (isUnauthorized && !isCurrentUserQuery) await userClient.refetchQueries({ include: ['currentUser'] });
        throw err;
      })
      .catch(() => ({ errors: graphQLErrors }));

  return fromPromise(refreshOrLogout());
});

export const userClient = new ApolloClient({
  link: split(
    (operation) => operation.getContext().auth,
    from([setTokenLink, httpAuthLink]),
    from([refreshLink, authLink, httpLink]),
  ),
  defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' }, react: { suspense: { autoDisposeTimeoutMs: 1000 } } },
  cache,
});

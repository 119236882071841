import { Control, useController } from 'react-hook-form';
import { TemplateFields } from '../../template-resolver';
import { FC } from 'react';
import { getSenderMessage } from './utils/sender-messages.util';
import { FormField } from '@app/layout/form-field';
import { Text } from '@app/layout/text';
import { Button } from '@app/components/button';
import { Input } from '@app/components/input';
import { useDebounce } from '@app/hooks/use-debounce';
import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { graphql } from '@app/__generated__';
import { useMutation, useQuery } from '@apollo/client';
import { SenderStatus, SenderStatusDocument } from '@app/__generated__/graphql';

const senderStatusDocument = graphql(`
  query senderStatus($senderName: String!) {
    senderStatus(senderName: $senderName)
  }
`);

const addSenderDocument = graphql(`
  mutation addSender($senderName: String!) {
    addSender(senderName: $senderName)
  }
`);

interface SmsSenderFormFieldProps {
  control: Control<TemplateFields>;
}

export const SmsSenderFormField: FC<SmsSenderFormFieldProps> = ({ control }) => {
  const {
    formState: { errors },
    field,
  } = useController({ control, name: 'sms.sender' });

  const debouncedValue = useDebounce(field.value);

  const { data } = useQuery(senderStatusDocument, {
    variables: { senderName: debouncedValue },
    skip: !debouncedValue,
    fetchPolicy: 'network-only',
  });
  const [addSender, { loading }] = useMutation(addSenderDocument, {
    refetchQueries: [SenderStatusDocument],
    onError: getErrorHandler(),
  });

  const { message, color } = getSenderMessage(data?.senderStatus);
  const isAdded = data?.senderStatus !== SenderStatus.NotAdded;

  const handleAddSender = () => {
    addSender({ variables: { senderName: field.value } });
  };

  return (
    <FormField
      title="Sender"
      error={errors.sms?.sender}
      bottom={
        field.value && (
          <Text size="xs" color={color}>
            {message}
          </Text>
        )
      }
      right={
        <Button
          size="small"
          kind={!field.value || isAdded ? 'gray' : 'primary'}
          disabled={!field.value || isAdded}
          isLoading={loading}
          onClick={handleAddSender}
        >
          Add sender
        </Button>
      }
    >
      <Input isError={!!errors.sms?.sender} {...field} maxLength={11} />
    </FormField>
  );
};

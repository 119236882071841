import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { usePortalContainer } from '@app/hooks/use-portal-container';
import { Icon } from '@app/components/icon';
import { ModalWrapper, ModalCloseButton, ModalPageBg } from './modal.styles';
import { exactlyMouseHandler } from '@app/utils/handlers.util';

interface ModalProps {
  children: ReactNode;
  handleClose: () => void;
  slide?: boolean;
}

export const Modal: FC<ModalProps> = ({ slide, handleClose, children }) => {
  const el = usePortalContainer();

  return createPortal(
    <ModalPageBg onMouseDown={exactlyMouseHandler(() => handleClose())}>
      <ModalWrapper onSubmit={(e) => e.stopPropagation()} $slide={slide}>
        <ModalCloseButton size="small" onClick={() => handleClose()} $fixed={slide}>
          <Icon name="close" color="white" />
        </ModalCloseButton>
        {children}
      </ModalWrapper>
    </ModalPageBg>,
    el,
  );
};

import { FC } from 'react';
import { Control, useController } from 'react-hook-form';
import { FormField } from '@app/layout/form-field';
import { GalleryConfigFields } from '../gallery-config-resolver';
import { MultiUserSelect } from '@app/feautres/users/containers/multi-user-select';
import { Role } from '@app/__generated__/graphql';

interface TechniciansFormField {
  control: Control<GalleryConfigFields>;
}

export const TechniciansFormField: FC<TechniciansFormField> = ({ control }) => {
  const {
    fieldState: { error },
    field: { onChange, onBlur, value },
  } = useController({ control, name: 'gallery.techniciansIds' });

  const handleOnChange = (ids?: string[]) => {
    onChange(ids);
    onBlur();
  };

  return (
    <div style={{ display: 'none' }}>
      <FormField title="Technicians" error={error}>
        <MultiUserSelect role={Role.Technician} onChange={handleOnChange} value={value} isError={!!error} />
      </FormField>
    </div>
  );
};

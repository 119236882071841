import * as z from 'zod';
import { MessageStatus } from '@app/__generated__/graphql';

export const messagesFiltersSchema = z
  .object({
    contactPhrase: z.string(),
    status: z.nativeEnum(MessageStatus),
  })
  .partial();

export type MessagesFilters = z.infer<typeof messagesFiltersSchema>;

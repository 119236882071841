import styled from 'styled-components';

export const GalleryStatsSubpageWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grayLight};
  padding: 10px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
`;

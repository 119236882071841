import { ModalTrigger } from '@app/components/modal';
import { Button } from '@app/components/button';
import { Flex } from '@app/layout/flex';
import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { ViewGalleryType } from '../interfaces/view-gallery-type.interface';
import { useMutation } from '@apollo/client';
import { graphql } from '@app/__generated__';
import { GalleryTypesDocument } from '@app/__generated__/graphql';
import { useConfirm } from '@app/feautres/confirm/context/confirm-context';
import { GalleryTypeEdit } from '@app/feautres/gallery-type/containers/gallery-type-edit';
import { ResponsiveTableCell, ResponsiveTableRow } from '@app/layout/responsive-table';
import { GalleryTypesItemName } from './gallery-types-item.styles';

const deleteGalleryTypeDocument = graphql(`
  mutation deleteGalleryType($galleryTypeId: UUID!) {
    deleteGalleryType(galleryTypeId: $galleryTypeId)
  }
`);

interface GalleriesItemProps {
  galleryType: ViewGalleryType;
}

export const GalleryTypesItem = ({ galleryType }: GalleriesItemProps) => {
  const [remove, { loading }] = useMutation(deleteGalleryTypeDocument, {
    variables: { galleryTypeId: galleryType.id },
    refetchQueries: [GalleryTypesDocument],
    onError: getErrorHandler({ RELATION_ENTITY_ERROR: 'Cannot delete type used by gallery' }),
  });

  const confirmRemove = useConfirm({
    question: `Are you sure you want to delete ${galleryType.name}  ?`,
    actionName: 'Delete',
    cb: remove,
  });

  return (
    <ResponsiveTableRow>
      <ResponsiveTableCell $full>
        <GalleryTypesItemName>{galleryType.name}</GalleryTypesItemName>
      </ResponsiveTableCell>
      <ResponsiveTableCell>
        <Flex gap={0} row justifyContent="center">
          <ModalTrigger content={(close) => <GalleryTypeEdit galleryTypeId={galleryType.id} onSaved={close} />}>
            {(trigger) => <Button onClick={trigger} kind="white" size="small" icon="edit" />}
          </ModalTrigger>
          <Button kind="danger" size="small" icon="remove" isLoading={loading} onClick={confirmRemove} />
        </Flex>
      </ResponsiveTableCell>
    </ResponsiveTableRow>
  );
};

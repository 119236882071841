import { useLayoutEffect } from 'react';
import { Flex } from '@app/layout/flex';
import { PaginationButton } from './pagination.styles';
import { Icon } from '../icon';

interface PaginationProps {
  page: number;
  pagesCount: number;
  isLoading: boolean;
  onChange: (pageNumber: number) => void;
}

export const Pagination = ({ page, pagesCount, isLoading, onChange }: PaginationProps) => {
  useLayoutEffect(() => {
    if (page > pagesCount && pagesCount > 0) onChange(pagesCount);
  }, [pagesCount, page, onChange]);

  let startPage, endPage;
  if (pagesCount <= 3) {
    startPage = 1;
    endPage = pagesCount;
  } else {
    if (page <= 2) {
      startPage = 1;
      endPage = 3;
    } else if (page + 1 >= pagesCount) {
      startPage = pagesCount - 2;
      endPage = pagesCount;
    } else {
      startPage = page - 1;
      endPage = page + 1;
    }
  }

  const getButton = (i: number) => {
    const isActive = page === i;
    return (
      <PaginationButton
        kind={isActive ? 'primary' : 'white'}
        isLoading={isActive && isLoading}
        key={`page-${i}`}
        onClick={() => onChange(i)}
        value={i}
      >
        {i}
      </PaginationButton>
    );
  };

  const render = [];
  for (let i = startPage; i <= endPage; i++) {
    render.push(getButton(i));
  }

  return (
    <Flex gap={0} justifyContent="center" alignItems="center" row>
      <PaginationButton $rotate kind="white" disabled={page === 1} onClick={() => onChange(page - 1)}>
        <Icon name="arrowRightSm" />
      </PaginationButton>
      {startPage > 1 && getButton(1)}
      {startPage > 2 && '...'}
      {render}
      {endPage < pagesCount - 1 && '...'}
      {endPage < pagesCount && getButton(pagesCount)}
      <PaginationButton kind="white" disabled={page >= pagesCount} onClick={() => onChange(page + 1)}>
        <Icon name="arrowRightSm" />
      </PaginationButton>
    </Flex>
  );
};

import { createGlobalStyle } from 'styled-components';
import { calendarCss } from './calendar';

export const PanelGlobalStyles = createGlobalStyle`
  body {
    background: white;
  }
 
  ${calendarCss}
`;

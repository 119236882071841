import { useMemo } from 'react';
import { TemplateFields } from '../template-resolver';

export const useEmailPreviewMemo = (template: TemplateFields) => {
  const {
    logoUrl,
    logoWidth,
    logoPadding,
    headerText,
    topText,
    bottomText,
    buttonText,
    textColor,
    color,
    bgColor,
    contentBgColor,
    showBottomImg,
    customMjml,
  } = template.email;

  const previewData = useMemo(
    () => ({
      logoUrl,
      logoWidth,
      logoPadding,
      headerText,
      topText,
      bottomText,
      buttonText,
      textColor,
      color,
      bgColor,
      contentBgColor,
      showBottomImg,
      customMjml: customMjml && { id: customMjml.id, mjml: customMjml.mjml },
    }),
    [
      logoUrl,
      logoWidth,
      logoPadding,
      headerText,
      topText,
      bottomText,
      buttonText,
      textColor,
      color,
      bgColor,
      contentBgColor,
      showBottomImg,
      customMjml,
    ],
  );

  return previewData;
};

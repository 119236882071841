import * as z from 'zod';
import { CaptureStatus } from '@app/__generated__/graphql';

export const capturesFiltersSchema = z.object({
  phrase: z.string().optional(),
  status: z.nativeEnum(CaptureStatus).default(CaptureStatus.Processed),
  deleted: z.coerce.boolean().optional(),
  agreement: z.coerce.boolean().optional(),
});

export type CapturesFilters = z.infer<typeof capturesFiltersSchema>;

import { useFormContext, Controller } from 'react-hook-form';
import { TemplateFields } from '../template-resolver';
import { FC } from 'react';
import { Checkbox } from '@app/components/checkbox';
import { Flex } from '@app/layout/flex';
import { FormField } from '@app/layout/form-field';
import { AssetSelector } from '@app/feautres/gallery/containers/asset-selector';
import { Text } from '@app/layout/text';
import { Input, Textarea } from '@app/components/input';
import { Columns } from '@app/layout/columns';

export const QrFormPart: FC = () => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext<TemplateFields>();

  const values = watch();

  return (
    <Flex>
      <FormField title="Logo">
        <div>
          <AssetSelector onChange={(fileUrl) => setValue('qr.logoUrl', fileUrl)} />
          <Flex row>
            <Text>{values.qr.logoWidth}px</Text>
            <input
              style={{ width: '100%' }}
              type="range"
              {...register('qr.logoWidth', { valueAsNumber: true })}
              min="50"
              max="350"
            />
          </Flex>
        </div>
      </FormField>
      <FormField title="Header text" error={errors.qr?.headerText}>
        <Textarea isError={!!errors.qr?.headerText} {...register('qr.headerText')} />
      </FormField>
      <FormField title="Input text" error={errors.qr?.inputText}>
        <Input isError={!!errors.qr?.inputText} {...register('qr.inputText')} />
      </FormField>
      <FormField title="Button text" error={errors.qr?.buttonText}>
        <Input isError={!!errors.qr?.buttonText} {...register('qr.buttonText')} />
      </FormField>
      <FormField title="Success text" error={errors.qr?.successText}>
        <Input isError={!!errors.qr?.successText} {...register('qr.successText')} />
      </FormField>
      <FormField title="Color" error={errors.qr?.color}>
        <Input isError={!!errors.qr?.color} {...register('qr.color')} />
      </FormField>
      <Columns breakpoint="md">
        <FormField title="Background color" error={errors.qr?.bgColor}>
          <Input isError={!!errors.qr?.bgColor} {...register('qr.bgColor')} />
        </FormField>
        <FormField title="Text color" error={errors.qr?.textColor}>
          <Input isError={!!errors.qr?.textColor} {...register('qr.textColor')} />
        </FormField>
      </Columns>
      <Controller
        control={control}
        name="qr.showBottomImg"
        render={({ field: { onChange, value } }) => (
          <Checkbox checked={value} onChange={onChange}>
            Powered by PICSANE
          </Checkbox>
        )}
      />
    </Flex>
  );
};

import styled, { css } from 'styled-components';
import { Button } from '@app/components/button';
import { topSlideIn } from '@app/styles/animations/top-slide-in';
import { fadeIn } from '@app/styles/animations/fade-in';
import { rightSlideIn } from '@app/styles/animations/right-slide-in';

export const ModalPageBg = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const ModalWrapper = styled.div<{ $slide?: boolean }>`
  position: absolute;
  padding: 30px;
  box-sizing: border-box;
  background: white;
  ${({ $slide }) =>
    $slide
      ? css`
          right: 0;
          top: 0;
          border-left: 1px solid ${({ theme }) => theme.colors.grayLight};
          width: 800px;
          max-width: 100vw;
          height: 100vh;
          animation: ${rightSlideIn} 0.6s ease-in-out;
          animation-timing-function: cubic-bezier(0.08, 0.88, 0.47, 0.97);
          overflow-y: auto;
          padding-bottom: 80px;

          @supports (height: 100vhd) {
            height: 100vhd;
            padding-bottom: 30px;
          }
        `
      : css`
          padding-top: 50px;
          box-shadow: ${({ theme }) => theme.shadows.base};
          min-width: 250px;
          animation: ${topSlideIn} 0.6s ease-in-out;
          animation-timing-function: cubic-bezier(0.08, 0.88, 0.47, 0.97);
        `}
`;

export const ModalCloseButton = styled(Button)<{ $fixed?: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(20%, -20%);
  z-index: 10;

  ${({ $fixed }) =>
    $fixed &&
    css`
      position: fixed;
      transform: translate(-50%, 10%);
    `};
`;

import { Outlet, useParams } from 'react-router-dom';
import { Flex } from '@app/layout/flex';
import { GalleryPageNav } from './components/gallery-page-nav';
import { Boundary } from '@app/components/boundary';
import { GalleryIdProvider } from '@app/feautres/gallery/providers/gallery-id-provider';
import { graphql } from '@app/__generated__';
import { useSuspenseQuery } from '@apollo/client';
import { PageLayout } from '@app/panel/layout/page';

const galleryBaseDocument = graphql(`
  query galleryBase($galleryId: UUID!) {
    gallery(galleryId: $galleryId) {
      id
      name
    }
  }
`);

export const GalleryPage = () => {
  const galleryId = useParams().galleryId as string;
  const { data } = useSuspenseQuery(galleryBaseDocument, { variables: { galleryId } });

  return (
    <GalleryIdProvider galleryId={galleryId}>
      <PageLayout title={data.gallery.name}>
        <Flex gap={50}>
          <GalleryPageNav />
          <Boundary>
            <Outlet />
          </Boundary>
        </Flex>
      </PageLayout>
    </GalleryIdProvider>
  );
};

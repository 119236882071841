import { FC, useEffect, useRef, useState, useTransition } from 'react';
import { EmailPreviewContent } from './email-preview.styles';
import { TemplateFields } from '../template-resolver';
import { Button } from '@app/components/button';
import { Flex } from '@app/layout/flex';
import { graphql } from '@app/__generated__';
import { useSuspenseQuery } from '@apollo/client';
import { useDebounce } from '@app/hooks/use-debounce';
import { useEmailPreviewMemo } from './use-email-preview-memo';
import { downloadEmailPreview } from './download-email-preview';

const emailPreviewDocument = graphql(`
  query emailPreview($data: EmailLayoutDataInput!) {
    emailPreview(data: $data)
  }
`);

interface EmailPreviewProps {
  template: TemplateFields;
  shouldRender: boolean;
}

export const EmailPreview: FC<EmailPreviewProps> = ({ template, shouldRender }) => {
  const previewData = useEmailPreviewMemo(template);

  const toRender = useDebounce(previewData, { shouldUpdate: shouldRender });

  const [firstRender] = useState(toRender);

  const { data, refetch } = useSuspenseQuery(emailPreviewDocument, {
    variables: { data: firstRender },
    fetchPolicy: 'no-cache',
  });

  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    if (toRender === firstRender) return;
    startTransition(() => {
      refetch({ data: toRender });
    });
  }, [refetch, toRender, firstRender]);

  const ref = useRef<HTMLIFrameElement>(null);

  const download = () => {
    downloadEmailPreview(`
      <div style='padding: 20px; font-family: Helvetica, sans-serif;'>
        <div style='font-size: 20px;'>${template.email.subject}</div>
          <div style='font-size: 14px;'>
            From: <b>${template.email.fromName}</b>
          </div>
      </div>
      ${data.emailPreview}
    `);
  };

  useEffect(() => {
    const iframe = ref.current;
    const iframeDoc = ref.current?.contentDocument;
    if (!iframe || !iframeDoc) return;

    const resizeIframe = () => {
      iframe.style.height = iframeDoc.getElementById('content')!.scrollHeight + 'px';
      if (iframe.contentDocument) iframe.contentDocument.body.style.overflow = 'hidden';
    };

    iframe.addEventListener('load', resizeIframe);
    window.addEventListener('resize', resizeIframe);

    return () => {
      iframe.removeEventListener('load', resizeIframe);
      window.removeEventListener('resize', resizeIframe);
    };
  }, []);

  useEffect(() => {
    const iframeDoc = ref.current?.contentDocument;
    if (!iframeDoc) return;
    iframeDoc.open();
    iframeDoc.write(`
      <div id='content'>
        ${data.emailPreview}
      </div>
    `);

    iframeDoc.close();
  }, [data.emailPreview, template.email, ref]);

  return (
    <Flex alignItems="center" style={{ opacity: isPending ? '0.2' : '1' }}>
      <Button icon="download" size="small" onClick={() => download()}>
        Download preview
      </Button>
      <EmailPreviewContent ref={ref} />
    </Flex>
  );
};

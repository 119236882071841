import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const MessagesFiltersViewWrapper = styled.div`
  display: grid;
  gap: 20px;
  align-items: center;

  width: 90%;

  ${breakpointsMedia.md} {
    width: 600px;
    gap: 30px;
    grid-template-columns: 1fr 1fr;
  }

  margin: 0 auto;
`;

import { FC } from 'react';
import { Control, useController } from 'react-hook-form';
import { ModalTrigger } from '@app/components/modal';
import { Flex } from '@app/layout/flex';
import { FormField } from '@app/layout/form-field';
import { Text } from '@app/layout/text';
import { Button } from '@app/components/button';
import { GalleryConfigFields } from '../gallery-config-resolver';
import { ThumbnailPositionEditor } from './thumbnail-position-editor';

interface ThumbnailPosFormFieldProps {
  control: Control<GalleryConfigFields>;
}

export const ThumbnailPosFormField: FC<ThumbnailPosFormFieldProps> = ({ control }) => {
  const {
    field: { onChange, value },
  } = useController({ control, name: 'gallery.thumbnailPosition' });

  return (
    <FormField title="Thumbnail position">
      <Flex alignItems="center" row>
        <Text>Current: {value}%</Text>
        <ModalTrigger
          content={(close) => (
            <ThumbnailPositionEditor
              onSubmit={(value) => {
                onChange(value);
                close();
              }}
            />
          )}
        >
          {(trigger) => (
            <Button type="button" size="small" onClick={trigger}>
              Set position
            </Button>
          )}
        </ModalTrigger>
      </Flex>
    </FormField>
  );
};

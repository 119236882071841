import { FC, useEffect, useMemo, useState } from 'react';
import { QrLandingPreviewWrapper } from './qr-landing-preview.styles';
import { TemplateModel } from '@app/__generated__/graphql';
import { Flex } from '@app/layout/flex';
import { Button } from '@app/components/button';
import { useHtmlJpg } from '@app/hooks/use-html-jpg';
import { QrView } from '../../qr-view';
import { useDebounce } from '@app/hooks/use-debounce';

interface QrLandingPreviewProps {
  template: TemplateModel;
  shouldRender: boolean;
}

export const QrLandingPreview: FC<QrLandingPreviewProps> = ({ template, shouldRender }) => {
  const [isSuccess, setIsSuccess] = useState(false);

  const qrTemplate = useMemo(() => ({ ...template.qr }), [template]);
  const displayTemplate = useDebounce(qrTemplate, { shouldUpdate: shouldRender });

  useEffect(() => {
    if (!isSuccess) return;
    const timeout = setTimeout(() => setIsSuccess(false), 5000);
    return () => clearTimeout(timeout);
  }, [isSuccess]);

  const [ref, download] = useHtmlJpg({ filename: 'qr-preview', width: 350, height: 600, scale: 2 });

  return (
    <Flex alignItems="center">
      <Button icon="download" size="small" onClick={() => download()}>
        Download preview
      </Button>
      <QrLandingPreviewWrapper ref={ref} $bgColor={displayTemplate.bgColor}>
        <QrView template={displayTemplate} isSuccess={isSuccess} isError={false} onSubmit={() => setIsSuccess(true)} noValidate />
      </QrLandingPreviewWrapper>
    </Flex>
  );
};

import { FC } from 'react';
import { Flex } from '@app/layout/flex';
import { RouterPagination } from '@app/components/pagination';
import { GalleriesView } from '../../views/galleries';
import { useRouterPage } from '@app/hooks/use-router-page';
import { useSuspenseQuery } from '@apollo/client';
import { graphql } from '@app/__generated__';
import { GalleriesFiltersInput } from '@app/__generated__/graphql';

const galleriesDocument = graphql(`
  query galleries($page: Int!, $limit: Int!, $filters: GalleriesFiltersInput) {
    galleries(page: $page, limit: $limit, filters: $filters) {
      count
      pagesCount
      items {
        id
        name
        startsAt
        endsAt
        capturesCount
        removalDate
        type {
          name
        }
      }
    }
  }
`);

interface GalleriesProps {
  filters?: GalleriesFiltersInput;
}

export const Galleries: FC<GalleriesProps> = ({ filters }) => {
  const page = useRouterPage();

  const { data } = useSuspenseQuery(galleriesDocument, {
    variables: { page, limit: 12, filters },
  });
  const { pagesCount, items } = data.galleries;

  return (
    <Flex>
      <GalleriesView galleries={items} />
      <RouterPagination pagesCount={pagesCount} />
    </Flex>
  );
};

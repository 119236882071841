import { FC, useEffect, useState } from 'react';
import { useForm, Controller, DefaultValues } from 'react-hook-form';
import slugify from 'slugify';
import { RangeCalendar } from '@app/components/range-calendar';
import { Flex } from '@app/layout/flex';
import { Input } from '@app/components/input';
import { Checkbox } from '@app/components/checkbox';
import { Button } from '@app/components/button';
import { FormField } from '@app/layout/form-field';
import { LayoutItem } from '@app/layout/layout-item';
import { Columns } from '@app/layout/columns';
import { GalleryTypeFormField } from './gallery-type';
import { ThumbnailPosFormField } from './thumbnail-pos';
import dayjs from 'dayjs';
import { GalleryConfigFields, galleryConfigResolver } from './gallery-config-resolver';
import _ from 'lodash-es';
import { EventManagerFormField } from './event-manager';
import { TechniciansFormField } from './technicians';
import { ContactsFormField } from './contacts';

const defaults = {
  gallery: {
    name: '',
    slug: '',
    thumbnailPosition: 50,
    startsAt: dayjs().startOf('date').toISOString(),
    endsAt: dayjs().startOf('date').add(1, 'day').subtract(1, 'minute').toISOString(),
    techniciansIds: [],
    contacts: [],
  },
  statsConfig: {
    isPeopleCounterActive: true,
  },
};

interface GalleryBaseFormViewProps {
  data?: DefaultValues<GalleryConfigFields>;
  onSubmit: (data: GalleryConfigFields) => void;
  loading: boolean;
  isCreate?: boolean;
}

export const GalleryBaseFormView: FC<GalleryBaseFormViewProps> = ({ data, onSubmit, loading, isCreate }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    getValues,
  } = useForm<GalleryConfigFields>({
    defaultValues: _.merge({}, defaults, data),
    resolver: galleryConfigResolver,
    mode: 'onBlur',
    shouldUnregister: true,
  });

  const getSlug = (text: string) => slugify(text, { strict: true, lower: true });

  const [isCustomSlug, setIsCustomSlug] = useState(getSlug(getValues('gallery.name')) !== getValues('gallery.slug'));

  const name = watch('gallery.name');
  useEffect(() => {
    if (isCustomSlug) return;
    setValue('gallery.slug', getSlug(name));
  }, [isCustomSlug, name, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap={70}>
        <Columns>
          <Flex>
            <FormField title="Name" error={errors.gallery?.name}>
              <Input isError={!!errors.gallery?.name} {...register('gallery.name')} />
            </FormField>
            <Flex gap={0}>
              <FormField
                title="Slug"
                error={isCustomSlug ? errors.gallery?.slug : undefined}
                bottom={
                  <Checkbox checked={isCustomSlug} onChange={setIsCustomSlug}>
                    Custom
                  </Checkbox>
                }
              >
                <Input isError={isCustomSlug && !!errors.gallery?.slug} {...register('gallery.slug')} disabled={!isCustomSlug} />
              </FormField>
            </Flex>
            <GalleryTypeFormField control={control} />
            <EventManagerFormField control={control} />
            <TechniciansFormField control={control} />
            <ContactsFormField control={control} />
            <ThumbnailPosFormField control={control} />
            <Controller
              control={control}
              name="statsConfig.isPeopleCounterActive"
              render={({ field: { onChange, value } }) => (
                <Checkbox checked={value} onChange={onChange}>
                  People counter
                </Checkbox>
              )}
            />
          </Flex>
          <Flex>
            <FormField title="Service time" error={errors.gallery?.endsAt}>
              <RangeCalendar control={control} startName="gallery.startsAt" endName="gallery.endsAt" />
            </FormField>
          </Flex>
        </Columns>
        <LayoutItem>
          <Button icon="save" type="submit" isLoading={loading}>
            {isCreate ? 'Create' : 'Save'}
          </Button>
        </LayoutItem>
      </Flex>
    </form>
  );
};

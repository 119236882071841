import { FC } from 'react';
import { Control, useController } from 'react-hook-form';
import { ModalTrigger } from '@app/components/modal/modal-trigger';
import { Button } from '@app/components/button';
import { GalleryTypeCreateContainer } from '@app/feautres/gallery-type/containers/gallery-type-create';
import { FormField } from '@app/layout/form-field';
import { GalleryTypeSelect } from '@app/feautres/gallery-type/containers/gallery-type-select';
import { GalleryConfigFields } from '../gallery-config-resolver';

interface GalleryTypeFormFieldProps {
  control: Control<GalleryConfigFields>;
}

export const GalleryTypeFormField: FC<GalleryTypeFormFieldProps> = ({ control }) => {
  const {
    fieldState: { error },
    field: { onChange, onBlur, value },
  } = useController({ control, name: 'gallery.galleryTypeId' });

  const handleOnChange = (id: string | null) => {
    onChange(id || undefined);
    onBlur();
  };

  return (
    <FormField
      title="Type"
      error={error}
      right={
        <ModalTrigger
          content={(close) => (
            <GalleryTypeCreateContainer
              onCreated={(galleryTypeId) => {
                handleOnChange(galleryTypeId);
                close();
              }}
            />
          )}
        >
          {(trigger) => (
            <Button type="button" size="small" onClick={trigger}>
              +
            </Button>
          )}
        </ModalTrigger>
      }
    >
      <GalleryTypeSelect onChange={handleOnChange} value={value} isError={!!error} />
    </FormField>
  );
};

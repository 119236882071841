import dayjs from 'dayjs';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { errorMap } from '@app/utils/zod.util';
import * as z from 'zod';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-tooltip/dist/react-tooltip.css';

z.setErrorMap(errorMap);
dayjs.extend(relativeTime);
dayjs.extend(utc);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

import { Flex } from '@app/layout/flex';
import { useNavigate } from 'react-router-dom';
import { Button } from '@app/components/button';
import { Boundary } from '@app/components/boundary';
import { galleriesFiltersSchema } from '@app/feautres/gallery/views/galleries-filters/galleries-filters-schema';
import { useFilters } from '@app/hooks/use-filters';
import { GalleriesFiltersView } from '@app/feautres/gallery/views/galleries-filters';
import { PageLayout } from '@app/panel/layout/page';
import { Galleries } from '@app/feautres/gallery/containers/galleries';
import { useCurrentUser } from '@app/panel/hooks/use-current-user';
import { useMemo } from 'react';
import { GalleriesFiltersInput, Role } from '@app/__generated__/graphql';

export const GalleriesPage = () => {
  const navigate = useNavigate();
  const { filters, update } = useFilters(galleriesFiltersSchema);

  const currentUser = useCurrentUser();

  const queryFilters = useMemo(() => {
    if (!filters) return;
    const { namePhrase, galleryTypeId, my, deleted } = filters;
    const queryFilters: GalleriesFiltersInput = { namePhrase, galleryTypeId, deleted };
    if (my && currentUser.role === Role.EventManager) queryFilters.eventManagerId = currentUser.id;
    return queryFilters;
  }, [filters, currentUser]);

  return (
    <PageLayout
      title="Galleries"
      right={
        <Button icon="add" onClick={() => navigate(`../new`)}>
          Create
        </Button>
      }
    >
      <Flex>
        <GalleriesFiltersView filters={filters} onUpdate={update} />
        <Boundary>
          <Galleries filters={queryFilters} />
        </Boundary>
      </Flex>
    </PageLayout>
  );
};

import { FC, ReactNode, useState } from 'react';
import { Modal } from './modal';
import { Boundary } from '../boundary';

interface ModalTriggerProps {
  content: ((handleClose: () => void) => ReactNode) | ReactNode;
  children: (trigger: () => void) => ReactNode;
}

export const ModalTrigger: FC<ModalTriggerProps> = ({ content, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const displayContent = typeof content === 'function' ? content(() => setIsOpen(false)) : content;

  return (
    <>
      {children(() => setIsOpen(true))}
      {isOpen && (
        <Modal slide handleClose={() => setIsOpen(false)}>
          <Boundary>{displayContent}</Boundary>
        </Modal>
      )}
    </>
  );
};

import { Textarea } from '@app/components/input';
import { useFormContext } from 'react-hook-form';
import { TemplateFields } from '../template-resolver';
import { FC, useState } from 'react';
import { FormField } from '@app/layout/form-field';
import { Flex } from '@app/layout/flex';
import { Button } from '@app/components/button';

interface CustomMjmlProps {
  onSubmit: () => void;
}

export const CustomMjml: FC<CustomMjmlProps> = ({ onSubmit }) => {
  const { setValue, getValues } = useFormContext<TemplateFields>();
  const [mjml, setMjml] = useState(getValues('email.customMjml.mjml') || '');

  const handleSave = () => {
    setValue('email.customMjml', mjml ? { id: Date.now().toString(), mjml } : null);
    onSubmit();
  };

  return (
    <Flex>
      <FormField title="Custom mjml">
        <Textarea value={mjml} onChange={(e) => setMjml(e.target.value)} />
      </FormField>
      <Button onClick={handleSave}>Save</Button>
    </Flex>
  );
};

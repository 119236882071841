import { Flex } from '@app/layout/flex';
import { useFilters } from '@app/hooks/use-filters';
import { capturesFiltersSchema } from '@app/feautres/capture/views/captures-filters/captures-filters-schema';
import { Boundary } from '@app/components/boundary';
import { useGalleryId } from '@app/feautres/gallery/contexts/gallery-id.context';
import { CapturesFiltersView } from '@app/feautres/capture/views/captures-filters';
import { AdminCaptures } from '@app/feautres/capture/containers/admin-captures';

export const GalleryCapturesSubpage = () => {
  const galleryId = useGalleryId();
  const { filters, update } = useFilters(capturesFiltersSchema);

  return (
    <Flex>
      <CapturesFiltersView filters={filters} onUpdate={update} />
      <Boundary>
        <AdminCaptures galleryId={galleryId} filters={filters} />
      </Boundary>
    </Flex>
  );
};

import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Input } from './input';
import { useDebounce } from '@app/hooks/use-debounce';

interface SearchInputProps {
  onSearch: (value: string) => void;
  initialValue?: string;
}

export const SearchInput: FC<SearchInputProps> = ({ onSearch, initialValue }) => {
  const [value, setValue] = useState(initialValue || '');
  const debouncedValue = useDebounce(value);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (isTouched) onSearch(debouncedValue);
  }, [debouncedValue, isTouched, onSearch]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isTouched) setIsTouched(true);
    setValue(e.currentTarget.value);
  };

  return <Input placeholder="Search..." value={value} onChange={handleChange} autoCorrect="off" autoCapitalize="none" />;
};

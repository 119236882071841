import { FC } from 'react';
import { Select } from '@app/components/select';
import { graphql } from '@app/__generated__';
import { useSuspenseQuery } from '@apollo/client';

const galleryTypesDocument = graphql(`
  query galleryTypes {
    galleryTypes {
      id
      name
    }
  }
`);

interface GalleryTypeSelectProps {
  value?: string;
  onChange: (value: string | null) => void;
  isError?: boolean;
}

export const GalleryTypeSelect: FC<GalleryTypeSelectProps> = ({ value, onChange, isError }) => {
  const { data } = useSuspenseQuery(galleryTypesDocument, { fetchPolicy: 'cache-first' });

  const options = data.galleryTypes.map(({ id, name }) => ({ value: id, label: name }));

  const currentOption = options.find((c) => c.value === value) || null;
  return (
    <Select
      options={options}
      value={currentOption}
      onChange={(option) => onChange(option?.value || null)}
      placeholder="Select type..."
      isError={isError}
      isClearable
    />
  );
};

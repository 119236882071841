import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const SidebarWrapper = styled.div<{ $open: boolean }>`
  position: fixed;
  left: 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100vw;
  background: ${({ theme }) => theme.colors.primary};
  z-index: 10;
  transition: transform 0.3s ease-in-out;

  bottom: 55px;
  transform: translateY(100%);

  ${({ $open }) =>
    $open &&
    css`
      transform: translateY(55px);
    `}

  ${breakpointsMedia.md} {
    transform: translateY(0);
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 100vh;
    gap: 20px;
    padding: 20px 0;
  }
`;

export const SidebarLogout = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

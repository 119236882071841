import { graphql } from '@app/__generated__';
import { userClient } from '../apollo/user-client';

const state: { token: string | null; refreshPromise: Promise<void> | null } = {
  token: null,
  refreshPromise: null,
};

export const setUserToken = (token: string) => {
  state.token = token;
};

const refreshDocument = graphql(`
  mutation userRefresh {
    userRefresh {
      accessToken
    }
  }
`);

export const refresh = async () => {
  if (!state.refreshPromise) {
    state.refreshPromise = userClient
      .mutate({ mutation: refreshDocument, context: { auth: true } })
      .then(({ data }) => {
        if (!data) throw new Error('Cannot refresh token');
        setUserToken(data.userRefresh.accessToken);
      })
      .finally(() => {
        state.refreshPromise = null;
      });
  }
  return state.refreshPromise;
};

refresh().catch(() => null);

export const getAccessToken = async () => {
  if (state.refreshPromise) await state.refreshPromise.catch(() => null);
  return state.token;
};

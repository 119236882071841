import { Boundary } from '@app/components/boundary';
import { MessagesContainer } from '@app/feautres/message/containers/messages';
import { MessagesFiltersView } from '@app/feautres/message/views/messages-filters';
import { messagesFiltersSchema } from '@app/feautres/message/views/messages-filters/messages-filters-schema';
import { useFilters } from '@app/hooks/use-filters';
import { Flex } from '@app/layout/flex';
import { PageLayout } from '@app/panel/layout/page';

export const MessagesPage = () => {
  const { filters, update } = useFilters(messagesFiltersSchema);

  return (
    <PageLayout title="Messages">
      <Flex>
        <MessagesFiltersView filters={filters} onUpdate={update} />
        <Boundary>
          <MessagesContainer filters={filters} />
        </Boundary>
      </Flex>
    </PageLayout>
  );
};

import { FC, ReactNode } from 'react';
import { PageLayoutContent, PageLayoutTop, PageLayoutWrapper } from './page-layout.styles';
import { Text } from '@app/layout/text';

interface PageLayoutProps {
  children?: ReactNode;
  right?: ReactNode;
  title?: string;
}

export const PageLayout: FC<PageLayoutProps> = ({ children, title, right }) => {
  return (
    <PageLayoutWrapper>
      <PageLayoutTop>
        <Text size="lg">{title}</Text>
        {right}
      </PageLayoutTop>
      <PageLayoutContent>{children}</PageLayoutContent>
    </PageLayoutWrapper>
  );
};

import styled from 'styled-components';
import { Button } from '@app/components/button';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const PaginationButton = styled(Button)<{ $rotate?: boolean }>`
  padding: 10px 12px;
  transform: rotate(${({ $rotate }) => ($rotate ? '180' : '0')}deg);

  ${breakpointsMedia.md} {
    padding: 10px 20px;
  }
`;

import styled from 'styled-components';

export const CopyFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px;
`;

export const CopyFieldArea = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  border: 1px solid ${({ theme }) => theme.colors.grayLight};
  padding: 5px 10px;
  overflow: hidden;

  white-space: pre-line;
`;

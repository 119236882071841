import { FC, useState } from 'react';
import { ConfirmContext } from '../context/confirm-context';
import { ConfirmData } from '../interfaces/confirm-data.interface';
import { ConfirmModal } from '../components/confirm-modal';

interface ConfirmProviderProps {
  children?: React.ReactNode;
}

export const ConfirmProvider: FC<ConfirmProviderProps> = ({ children }) => {
  const [confirmData, setConfirmData] = useState<ConfirmData | null>(null);

  const handleAccept = () => {
    confirmData?.cb();
    setConfirmData(null);
  };

  return (
    <ConfirmContext.Provider value={{ show: setConfirmData }}>
      {children}
      {confirmData && (
        <ConfirmModal
          question={confirmData.question}
          actionName={confirmData.actionName}
          onClose={() => setConfirmData(null)}
          onAccept={handleAccept}
        />
      )}
    </ConfirmContext.Provider>
  );
};

import axios from 'axios';
import { getAccessToken } from './user-auth.service';
import { getGalleryUploadAssetUrl } from '@app/utils/links.util';

export const axiosApi = axios.create();

axiosApi.interceptors.request.use(async (config) => {
  if (!config.url?.startsWith('/auth')) {
    const token = await getAccessToken();
    if (token) config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

interface UploadGalleryAssetConfig {
  galleryId: string;
  file: File;
}

export const uploadGalleryAsset = ({ galleryId, file }: UploadGalleryAssetConfig, signal: AbortSignal): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);

  return axiosApi
    .post(getGalleryUploadAssetUrl(galleryId), formData, {
      signal,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }) => data.file);
};

import { FC, useEffect, useRef, useState } from 'react';
import { Button } from '@app/components/button';
import { Input } from '@app/components/input';
import { UploadArea } from '@app/components/upload-area';
import { Flex } from '@app/layout/flex';
import { InfoGroup } from '@app/layout/info-group';
import { LayoutItem } from '@app/layout/layout-item';
import { Text } from '@app/layout/text';

interface ThumbnailPositionEditorProps {
  onSubmit: (value: number) => void;
}

export const ThumbnailPositionEditor: FC<ThumbnailPositionEditorProps> = ({ onSubmit }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [percentage, setPercentage] = useState(0);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!selectedFile || !videoRef.current) return;
    const media = URL.createObjectURL(selectedFile);
    videoRef.current.src = media;
    videoRef.current.currentTime = 0;
  }, [selectedFile]);

  useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = videoRef.current.duration * (percentage / 100) + 1 / 30;
  }, [percentage]);

  const changeFrame = (seek: number) => {
    if (!videoRef.current) return;
    const { currentTime, duration } = videoRef.current;
    const time = currentTime > 0 ? currentTime - 1 / 30 : 0;
    const newTime = time + seek / 30;
    if (newTime < 0) return setPercentage(0);
    if (newTime > duration) return setPercentage(100);
    setPercentage(Number(((newTime / duration) * 100).toFixed(2)));
  };

  if (!selectedFile)
    return (
      <Flex gap={60}>
        <Text size="lg">Thumbnail position</Text>
        <UploadArea accept={['video/mp4']} onSelect={setSelectedFile} />
      </Flex>
    );

  return (
    <Flex gap={60}>
      <Text size="lg">Thumbnail position</Text>
      <LayoutItem align="center">
        <video ref={videoRef} id="video" width="500" height="500"></video>
      </LayoutItem>
      <Flex gap={10}>
        <InfoGroup header="Current value" value={`${percentage.toFixed(2)}%`} />
        <Flex justifyContent="center" row>
          <Button onClick={() => changeFrame(-1)}>Prev frame</Button>
          <Button onClick={() => changeFrame(1)}>Next frame</Button>
        </Flex>
        <Input type="range" value={percentage} onChange={(e) => setPercentage(Number(e.target.value))} min="0" max="100" />
      </Flex>
      <LayoutItem>
        <Button icon="save" onClick={() => onSubmit(percentage)}>
          Save
        </Button>
      </LayoutItem>
    </Flex>
  );
};

import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const MessagesItemThumbnail = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;

  ${breakpointsMedia.md} {
    transition: 0.2s transform ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@app/components/button';
import { CopyFieldArea, CopyFieldWrapper } from './copy-field.styles';

interface CopyFieldProps {
  value: string;
}

export const CopyField: FC<CopyFieldProps> = ({ value }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;
    const timeout = setTimeout(() => setCopied(false), 3000);
    return () => clearTimeout(timeout);
  }, [copied]);

  const copyToClipboard = () =>
    navigator.clipboard
      .writeText(value)
      .then(() => setCopied(true))
      .catch(() => toast('Failed to copy', { type: 'error' }));

  return (
    <CopyFieldWrapper>
      <CopyFieldArea>{value}</CopyFieldArea>
      <Button icon={copied ? undefined : 'copy'} kind={copied ? 'gray' : 'primary'} disabled={copied} onClick={copyToClipboard}>
        {copied ? 'Copied' : 'Copy'}
      </Button>
    </CopyFieldWrapper>
  );
};

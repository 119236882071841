import { FC, useCallback, useState } from 'react';
import { SearchInput } from '@app/components/input/search-input';
import { GalleryTypeSelect } from '@app/feautres/gallery-type/containers/gallery-type-select';
import { GalleriesFiltersViewWrapper } from './galleries-filters-view.styles';
import { GalleriesFilters } from './galleries-filters-schema';
import { FiltersViewProps } from '@app/interfaces/filters-view-props.interface';
import { useBreakpoint } from '@app/hooks/use-breakpoint';
import { Button } from '@app/components/button';
import { Checkbox } from '@app/components/checkbox';
import { LayoutItem } from '@app/layout/layout-item';
import { useCurrentUser } from '@app/panel/hooks/use-current-user';
import { Role } from '@app/__generated__/graphql';
import { Flex } from '@app/layout/flex';

export const GalleriesFiltersView: FC<FiltersViewProps<GalleriesFilters>> = ({ filters, onUpdate }) => {
  const handleNameChange = useCallback(
    (value: string) => {
      onUpdate({ namePhrase: value });
    },
    [onUpdate],
  );

  const currentUser = useCurrentUser();

  const [isActive, setIsActive] = useState(false);
  const isDesktop = useBreakpoint('md');
  if (!isActive && !isDesktop) return <Button onClick={() => setIsActive(true)}>Filters</Button>;

  return (
    <GalleriesFiltersViewWrapper>
      <GalleryTypeSelect value={filters.galleryTypeId} onChange={(id) => onUpdate({ galleryTypeId: id })} />
      <SearchInput onSearch={handleNameChange} />
      <LayoutItem justify="center">
        <Flex gap={5}>
          <Checkbox checked={!!filters.deleted} onChange={(value) => onUpdate({ deleted: value ? '1' : null })}>
            Deleted
          </Checkbox>
          {currentUser.role === Role.EventManager && (
            <Checkbox checked={!!filters.my} onChange={(value) => onUpdate({ my: value ? '1' : null })}>
              Only my
            </Checkbox>
          )}
        </Flex>
      </LayoutItem>
    </GalleriesFiltersViewWrapper>
  );
};

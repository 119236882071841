import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const zodColor = z.string().regex(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i, 'Must be valid hex');

const emailSchema = z.object({
  customMjml: z.object({ id: z.string(), mjml: z.string() }).nullable().optional(),
  subject: z.string().min(3).max(70),
  fromName: z.string().min(3).max(50),
  logoUrl: z.string(),
  logoWidth: z.number().min(50).max(600),
  logoPadding: z.boolean(),
  headerText: z.string().max(1000),
  topText: z.string().max(1000),
  bottomText: z.string().max(1000),
  buttonText: z.string().min(3).max(30),
  textColor: zodColor,
  color: zodColor,
  bgColor: zodColor,
  contentBgColor: zodColor,
  showBottomImg: z.boolean(),
});

const smsSchema = z.object({
  sender: z.string().min(2).max(11),
  template: z
    .string()
    .min(10)
    .max(255)
    .regex(/{LINK}/, 'Template must contain {LINK}'),
});

const qrSchema = z.object({
  logoUrl: z.string(),
  logoWidth: z.number().min(50).max(500),
  headerText: z.string().max(255),
  inputText: z.string().max(100),
  buttonText: z.string().min(3).max(30),
  successText: z.string().min(3).max(60),
  textColor: zodColor,
  color: zodColor,
  bgColor: zodColor,
  showBottomImg: z.boolean(),
});

const templateSchema = z.object({
  email: emailSchema,
  sms: smsSchema,
  qr: qrSchema,
});

export type TemplateFields = z.infer<typeof templateSchema>;

export const templateResolver = zodResolver(templateSchema);

import { Flex } from '@app/layout/flex';
import { useFilters } from '@app/hooks/use-filters';
import { capturesFiltersSchema } from '@app/feautres/capture/views/captures-filters/captures-filters-schema';
import { Boundary } from '@app/components/boundary';
import { CapturesFiltersView } from '@app/feautres/capture/views/captures-filters';
import { PageLayout } from '@app/panel/layout/page';
import { AdminCaptures } from '@app/feautres/capture/containers/admin-captures';

export const CapturesPage = () => {
  const { filters, update } = useFilters(capturesFiltersSchema);

  return (
    <PageLayout title="Captures">
      <Flex>
        <CapturesFiltersView filters={filters} onUpdate={update} />
        <Boundary>
          <AdminCaptures filters={filters} />
        </Boundary>
      </Flex>
    </PageLayout>
  );
};

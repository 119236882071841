import { Navigate, useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import { SignInPageBackground, SignInPageWrapper } from './signin-page.styles';
import { Button } from '@app/components/button';
import { Text } from '@app/layout/text';
import { FormField } from '@app/layout/form-field';
import { Input } from '@app/components/input';
import { Icon } from '@app/components/icon';
import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { graphql } from '@app/__generated__';
import { useMutation } from '@apollo/client';
import { CurrentUserDocument } from '@app/__generated__/graphql';
import { SigninFields, signinResolver } from './signin-resolver';
import { useIsLogged } from '@app/panel/hooks/use-current-user';
import { Flex } from '@app/layout/flex';

const userSigninDocument = graphql(`
  mutation userSignin($email: String!, $password: String!) {
    userSignin(email: $email, password: $password) {
      accessToken
    }
  }
`);

export const SignInPage = () => {
  const { handleSubmit, register } = useForm<SigninFields>({ resolver: signinResolver });

  const [signIn, { loading }] = useMutation(userSigninDocument, {
    context: { auth: true },
    refetchQueries: [CurrentUserDocument],
    awaitRefetchQueries: true,
    onError: getErrorHandler(),
  });

  const pathname = useLocation().state?.pathname as string | undefined;
  const isLogged = useIsLogged();
  if (isLogged) return <Navigate to={pathname || '/'} replace />;

  const onSubmit = ({ email, password }: SigninFields) => {
    signIn({ variables: { email, password } });
  };

  return (
    <SignInPageBackground>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SignInPageWrapper>
          <Text size="lg">Sign in</Text>
          <Flex gap={5}>
            <FormField>
              <Input type="email" placeholder="e-mail" {...register('email')} />
            </FormField>
            <FormField>
              <Input type="password" placeholder="password" {...register('password')} />
            </FormField>
          </Flex>

          <Button type="submit" size="large" isLoading={loading}>
            <Icon name="tick" />
            SIGN IN
          </Button>
        </SignInPageWrapper>
      </form>
    </SignInPageBackground>
  );
};

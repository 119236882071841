import { SenderStatus } from '@app/__generated__/graphql';
import { Colors } from '@app/styles/types/colors.type';

const SenderMessages = {
  [SenderStatus.NotAdded]: 'is not added to verification',
  [SenderStatus.Pending]: 'is waiting for verification',
  [SenderStatus.Rejected]: 'is rejected',
  [SenderStatus.Deactivated]: 'is deactivated',
  [SenderStatus.AgreementRequired]: 'requires additional agreement',
  [SenderStatus.Authorized]: 'is authorized',
};

const getSenderMessageColor = (status: SenderStatus) => {
  if (status === SenderStatus.Authorized) return 'success';
  if (status === SenderStatus.Pending) return 'warning';
  return 'danger';
};

export const getSenderMessage = (status?: SenderStatus): { message: string; color: Colors } => {
  if (!status) return { message: 'Loading...', color: 'black' };
  const message = `Sender ${SenderMessages[status]}`;
  const color = getSenderMessageColor(status);

  return { message, color };
};

import styled from 'styled-components';
import phoneMockup from '@app/assets/phone-mockup.png';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const SmsPreviewWrapper = styled.div`
  --preview-width: 300px;

  ${breakpointsMedia.md} {
    --preview-width: 400px;
  }
`;

export const SmsPreviewPhone = styled.div`
  position: relative;
  --width: var(--preview-width, 800px);
  width: var(--width);
  height: 100%;
  background: url(${phoneMockup});
  background-size: var(--width);
  margin: 0 auto;
  transform-origin: left;
  padding-top: calc(var(--width) / 2);
  box-sizing: border-box;
`;

export const SmsPreviewSender = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(var(--width) * 0.293);
  font-size: calc(var(--width) * 0.025);
  font-weight: bold;
`;

export const SmsPreviewMessage = styled.div`
  word-break: break-word;
  width: calc(var(--width) / 2);
  font-size: calc(var(--width) * 0.035);
  border-radius: calc(var(--width) * 0.04);
  background: #eee;
  padding: calc(var(--width) * 0.02) calc(var(--width) * 0.04);
  margin-bottom: calc(var(--width) * 0.05);
  margin-left: calc(var(--width) * 0.1);

  & span {
    color: blue;
    text-decoration: underline;
  }
`;

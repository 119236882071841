import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const SidebarLinkWrapper = styled(NavLink)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  opacity: 0.7;

  &.active {
    opacity: 1;
    background: rgba(255, 255, 255, 0.1);
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 3px;
      width: 100%;
      background: ${({ theme }) => theme.colors.grayLight};

      ${breakpointsMedia.md} {
        top: 0;
        width: 3px;
        height: 100%;
      }
    }
  }
`;

import { Navigate, RouteObject } from 'react-router-dom';

interface PaginatedRouteConfig {
  path: string;
  element: JSX.Element;
  children?: RouteObject[];
}

export const paginatedRoute = ({ path, element, children = [] }: PaginatedRouteConfig): RouteObject => ({
  path,
  children: [
    ...children,
    {
      path: 'page/:page',
      element,
    },
    {
      path: '*?',
      element: <Navigate to={'page/1'} replace />,
    },
  ],
});

import styled from 'styled-components';

export const GalleryStatusSubpageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  max-width: 800px;
`;

import { breakpointsMedia } from '@app/styles/breakpoints';
import styled from 'styled-components';

export const EmailPreviewContent = styled.iframe`
  min-height: 700px;
  margin: 0 auto;
  border: 0;
  width: 100%;

  ${breakpointsMedia.md} {
    width: 600px;
  }
`;

import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { GalleriesDocument } from '@app/__generated__/graphql';
import { graphql } from '@app/__generated__';
import { useMutation } from '@apollo/client';
import { FC, ReactNode } from 'react';
import { useGalleryId } from '../../contexts/gallery-id.context';

const restoreGalleryDocument = graphql(`
  mutation restoreGallery($galleryId: UUID!) {
    restoreGallery(galleryId: $galleryId)
  }
`);

interface RestoreGalleryProps {
  children: (data: { restore: () => void; loading: boolean }) => ReactNode;
}

export const RestoreGallery: FC<RestoreGalleryProps> = ({ children }) => {
  const galleryId = useGalleryId();

  const [restore, { loading }] = useMutation(restoreGalleryDocument, {
    variables: { galleryId },
    refetchQueries: [GalleriesDocument],
    onError: getErrorHandler(),
  });

  return children({ restore, loading });
};

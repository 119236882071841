import { FC, ReactNode } from 'react';
import { Text } from '@app/layout/text';
import { CheckboxBox, CheckboxHiddenInput, CheckboxIcon, CheckboxWrapper } from './checkbox.styles';

interface CheckboxProps {
  children: ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const Checkbox: FC<CheckboxProps> = ({ checked, onChange, children }) => (
  <label>
    <CheckboxWrapper>
      <CheckboxHiddenInput type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} />
      <CheckboxBox $checked={checked}>
        <CheckboxIcon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </CheckboxIcon>
      </CheckboxBox>
      <Text size="xs" lineHeight={1}>
        {children}
      </Text>
    </CheckboxWrapper>
  </label>
);

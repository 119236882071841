import { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import { ModalTrigger } from '@app/components/modal/modal-trigger';
import { Text } from '@app/layout/text';
import { MessagesItemThumbnail } from './messages-item.styles';
import notLoadedImg from '@app/assets/not-loaded.svg';
import { formatDatetime } from '@app/utils/format.util';
import { CaptureDetailsContainer } from '@app/feautres/capture/containers/capture-details';
import { ViewMessage } from '../interfaces/message.interface';
import { ResponsiveTableCell, ResponsiveTableRow } from '@app/layout/responsive-table';

const getStatusColor = (status: string) => {
  if (status === 'SENT') return 'success';
  if (status === 'ERROR') return 'danger';
  return 'warning';
};

interface MessagesItemProps {
  message: ViewMessage;
}

export const MessagesItem: FC<MessagesItemProps> = ({ message }) => {
  const errorTooltip = message.errorMessage
    ? { 'data-tooltip-id': 'messages-item', 'data-tooltip-content': message.errorMessage }
    : {};

  const thumbnailUrl = message.capture?.thumbnail?.path || notLoadedImg;

  return (
    <ResponsiveTableRow>
      {message.capture && (
        <>
          <ResponsiveTableCell>
            <MessagesItemThumbnail>
              <ModalTrigger content={<CaptureDetailsContainer captureId={message.capture.id} />}>
                {(trigger) => <img src={thumbnailUrl} alt="thumb" height="100px" onClick={trigger} />}
              </ModalTrigger>
            </MessagesItemThumbnail>
          </ResponsiveTableCell>
          <ResponsiveTableCell $full $wrap>
            <Text center size="xs" color="black">
              {message.capture.uploadFilename}
              <Text center size="xs" color="grayDark">
                {message.capture.gallery.name}
              </Text>
            </Text>
          </ResponsiveTableCell>
        </>
      )}
      <ResponsiveTableCell>
        <Text center size="xs">
          {message.contact}
          <Text center size="xxs" color="grayDark">
            {message.type}
          </Text>
        </Text>
      </ResponsiveTableCell>
      <ResponsiveTableCell>
        <Text center size="xxs" color={getStatusColor(message.status)} {...errorTooltip}>
          {message.status}
          {message.sentAt && (
            <Text center color="grayDark" size="xs">
              {formatDatetime(message.sentAt)}
            </Text>
          )}
        </Text>
      </ResponsiveTableCell>
      <Tooltip id="messages-item" />
    </ResponsiveTableRow>
  );
};

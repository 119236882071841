import { useEffect, useState } from 'react';

interface UseDebounceConfig {
  delay?: number;
  shouldUpdate?: boolean;
}

export const useDebounce = <T>(value: T, { delay = 1000, shouldUpdate = true }: UseDebounceConfig = {}): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    if (!shouldUpdate) return;
    const timout = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(timout);
  }, [value, delay, shouldUpdate]);

  return debouncedValue;
};

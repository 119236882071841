import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { useCaptureId } from '@app/feautres/capture/contexts/capture-id.context';
import { CapturesDocument } from '@app/__generated__/graphql';
import { graphql } from '@app/__generated__';
import { useMutation } from '@apollo/client';
import { FC, ReactNode } from 'react';

const restoreCaptureDocument = graphql(`
  mutation restoreCapture($captureId: UUID!) {
    restoreCapture(captureId: $captureId)
  }
`);

interface RestoreCaptureProps {
  children: (data: { restore: () => void; loading: boolean }) => ReactNode;
}

export const RestoreCapture: FC<RestoreCaptureProps> = ({ children }) => {
  const captureId = useCaptureId();

  const [restore, { loading }] = useMutation(restoreCaptureDocument, {
    variables: { captureId },
    refetchQueries: [CapturesDocument],
    onError: getErrorHandler(),
  });

  return children({ restore, loading });
};

import { breakpointsMedia } from '@app/styles/breakpoints';
import styled from 'styled-components';

export const PageLayoutWrapper = styled.div`
  display: grid;
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
`;

export const PageLayoutTop = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};

  ${breakpointsMedia.md} {
    min-height: 60px;
  }
`;

export const PageLayoutContent = styled.div`
  padding: 20px;
  overflow-y: auto;
  padding-bottom: 100px;
`;

import { FC } from 'react';
import { graphql } from '@app/__generated__';
import { useSuspenseQuery } from '@apollo/client';
import { CaptureDetailsView } from '../../views/capture-details';

const captureDocument = graphql(`
  query capture($captureId: UUID!) {
    capture(captureId: $captureId, withDeleted: true) {
      id
      shortId
      uploadFilename
      status
      file {
        path
      }
      preview {
        path
      }
      createdAt
      metadata
    }
  }
`);

interface CaptureDetailsProps {
  captureId: string;
}
export const CaptureDetailsContainer: FC<CaptureDetailsProps> = ({ captureId }) => {
  const { data } = useSuspenseQuery(captureDocument, { variables: { captureId } });

  return <CaptureDetailsView capture={data.capture} />;
};

import { FC, useMemo } from 'react';
import { CapturesView } from '../../views/captures';
import { Flex } from '@app/layout/flex';
import { RouterPagination } from '@app/components/pagination';
import { useRouterPage } from '@app/hooks/use-router-page';
import { graphql } from '@app/__generated__';
import { CapturesFiltersInput } from '@app/__generated__/graphql';
import { useSuspenseQuery } from '@apollo/client';
import { AdminCaptureButtons } from '../../components/admin-capture-buttons';
import { CapturesFilters } from '../../views/captures-filters/captures-filters-schema';
import { useBreakpoint } from '@app/hooks/use-breakpoint';

const capturesDocument = graphql(`
  query captures($page: Int!, $limit: Int!, $filters: CapturesFiltersInput) {
    captures: pageCaptures(page: $page, limit: $limit, filters: $filters) {
      count
      pagesCount
      items {
        id
        shortId
        uploadFilename
        status
        deletedAt
        file {
          path
        }
        preview {
          path
        }
        thumbnail {
          path
        }
      }
    }
  }
`);

interface AdminCapturesProps {
  galleryId?: string;
  filters?: CapturesFilters;
}

export const AdminCaptures: FC<AdminCapturesProps> = ({ galleryId, filters }) => {
  const page = useRouterPage();

  const requestFilters = useMemo(() => {
    const input: CapturesFiltersInput = {
      galleryId,
      uploadFilenamePhrase: filters?.phrase,
      status: filters?.status,
      deleted: filters?.deleted,
      metadata: {},
    };
    if (filters?.agreement) input.metadata = { picsaneMarketingConsent: true };

    return input;
  }, [filters, galleryId]);

  const { data } = useSuspenseQuery(capturesDocument, { variables: { page, limit: 30, filters: requestFilters } });
  const { pagesCount, items } = data.captures;

  const isDesktop = useBreakpoint('lg');

  return (
    <Flex>
      {!isDesktop && !!items.length && <RouterPagination pagesCount={pagesCount} />}
      <CapturesView captures={items} buttons={(capture) => <AdminCaptureButtons capture={capture} />} />
      <RouterPagination pagesCount={pagesCount} />
    </Flex>
  );
};

import { FC } from 'react';
import { GalleriesItem } from './galleries-item';
import { PageInfo } from '@app/layout/page-info';
import { ViewGallery } from './interfaces/view-gallery.interface';
import { ResponsiveTable } from '@app/layout/responsive-table';

interface GalleriesViewProps {
  galleries: ViewGallery[];
}

export const GalleriesView: FC<GalleriesViewProps> = ({ galleries }) => {
  if (!galleries.length) return <PageInfo>Not found any galleries...</PageInfo>;

  return (
    <ResponsiveTable>
      <tbody>
        {galleries.map((gallery) => (
          <GalleriesItem key={gallery.id} gallery={gallery} />
        ))}
      </tbody>
    </ResponsiveTable>
  );
};

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const galleryTypeSchema = z.object({
  name: z.string().min(3).max(40),
});

export type GalleryTypeFields = z.infer<typeof galleryTypeSchema>;

export const galleryTypeResolver = zodResolver(galleryTypeSchema);

import { FC, useCallback, useState } from 'react';
import { SearchInput } from '@app/components/input';
import { CapturesFiltersViewWrapper } from './capture-filters-view.styles';
import { Select } from '@app/components/select';
import { CaptureStatus } from '@app/__generated__/graphql';
import { CapturesFilters } from './captures-filters-schema';
import { FiltersViewProps } from '@app/interfaces/filters-view-props.interface';
import { Checkbox } from '@app/components/checkbox';
import { Button } from '@app/components/button';
import { useBreakpoint } from '@app/hooks/use-breakpoint';
import { Flex } from '@app/layout/flex';

export const CapturesFiltersView: FC<FiltersViewProps<CapturesFilters>> = ({ filters, onUpdate }) => {
  const captureOptions: { value: CaptureStatus; label: string }[] = [
    { value: CaptureStatus.Processed, label: 'PROCESSED' },
    { value: CaptureStatus.Error, label: 'ERROR' },
    { value: CaptureStatus.Created, label: 'WAITING' },
  ];

  const currentOption = captureOptions.find((c) => c.value === filters.status) || null;

  const handleUploadFilenameChange = useCallback(
    (value: string) => {
      onUpdate({ phrase: value });
    },
    [onUpdate],
  );

  const [isActive, setIsActive] = useState(false);
  const isDesktop = useBreakpoint('md');
  if (!isActive && !isDesktop) return <Button onClick={() => setIsActive(true)}>Filters</Button>;

  return (
    <div>
      <CapturesFiltersViewWrapper>
        <SearchInput initialValue={filters.phrase} onSearch={handleUploadFilenameChange} />
        <Select
          placeholder="Select status..."
          options={captureOptions}
          value={currentOption}
          onChange={(status) => status && onUpdate({ status: status?.value || null })}
        />
        <Flex gap={5} alignItems="center">
          <Flex gap={5}>
            <Checkbox checked={!!filters.deleted} onChange={(value) => onUpdate({ deleted: value ? '1' : null })}>
              Deleted
            </Checkbox>
            <Checkbox checked={!!filters.agreement} onChange={(value) => onUpdate({ agreement: value ? '1' : null })}>
              Agreement
            </Checkbox>
          </Flex>
        </Flex>
      </CapturesFiltersViewWrapper>
    </div>
  );
};

import { FC } from 'react';
import { GalleryTypesView } from '../../views/gallery-types';
import { graphql } from '@app/__generated__';
import { useSuspenseQuery } from '@apollo/client';

const galleryTypesDocument = graphql(`
  query galleryTypes {
    galleryTypes {
      id
      name
    }
  }
`);

export const GalleryTypes: FC = () => {
  const { data } = useSuspenseQuery(galleryTypesDocument);

  return <GalleryTypesView galleryTypes={data.galleryTypes} />;
};

import styled from 'styled-components';

export const StatusPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 40px;

  max-width: 800px;
`;

import { createContext, useContext } from 'react';
import { ConfirmData } from '../interfaces/confirm-data.interface';

interface ConfirmContextData {
  show: (data: ConfirmData) => void;
}
export const ConfirmContext = createContext<ConfirmContextData | null>(null);

export const useConfirm = (confirmData: ConfirmData) => {
  const confirmContext = useContext(ConfirmContext);
  if (!confirmContext) throw new Error('There is not ConfirmProvider');
  return () => confirmContext.show(confirmData);
};

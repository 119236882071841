import { Button } from '@app/components/button';
import { Modal } from '@app/components/modal';
import { Flex } from '@app/layout/flex';
import { Text } from '@app/layout/text';
import { FC } from 'react';

interface ConfirmModalProps {
  question: string;
  actionName: string;
  onClose: () => void;
  onAccept: () => void;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({ question, actionName, onClose, onAccept }) => {
  return (
    <Modal handleClose={onClose}>
      <Flex>
        <Text size="xs">{question}</Text>
        <Flex justifyContent="flex-end" gap={5} row>
          <Button kind="white" size="small" onClick={onClose}>
            Cancel
          </Button>
          <Button kind="danger" size="small" onClick={onAccept}>
            {actionName}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  user-select: none;
`;

export const CheckboxHiddenInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const CheckboxIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const CheckboxBox = styled.div<{ $checked: boolean }>`
  width: 16px;
  height: 16px;
  background: ${({ theme, $checked }) => theme.colors[$checked ? 'primary' : 'white']};
  border-radius: 3px;
  transition: all 150ms;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.gray};

  ${CheckboxHiddenInput}:focus + & {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.gray};
  }

  ${CheckboxIcon} {
    visibility: ${({ $checked }) => ($checked ? 'visible' : 'hidden')};
  }
`;

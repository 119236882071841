import { FC } from 'react';
import { Text } from '@app/layout/text';
import { MessagesView } from '@app/feautres/message/views/messages-view';
import { useSuspenseQuery } from '@apollo/client';
import { graphql } from '@app/__generated__';

const messagesDocument = graphql(`
  query messages($page: Int!, $limit: Int!, $filters: MessagesFiltersInput) {
    messages(page: $page, limit: $limit, filters: $filters) {
      count
      pagesCount
      items {
        id
        contact
        type
        status
        sentAt
        errorMessage
      }
    }
  }
`);

interface CaptureMessagesProps {
  captureId: string;
}

export const CaptureMessages: FC<CaptureMessagesProps> = ({ captureId }) => {
  const { data } = useSuspenseQuery(messagesDocument, { variables: { page: 1, limit: 100, filters: { captureId } } });

  if (!data.messages.count) return <Text center>Not found any messages...</Text>;
  return <MessagesView messages={data.messages.items} />;
};

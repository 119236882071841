import html2canvas from 'html2canvas';
import { useRef } from 'react';
import { toast } from 'react-toastify';

interface HtmlPreviewConfig {
  filename: string;
  width: number;
  height?: number;
  scale?: number;
}

export const downloadElementAsJpg = (div: HTMLElement, filename: string, scale?: number) => {
  return html2canvas(div, { allowTaint: true, useCORS: true, scale })
    .then((canvas) => {
      const canvasUrl = canvas.toDataURL('image/jpeg', 0.9);
      const link = document.createElement('a');
      link.href = canvasUrl;
      link.download = `${filename}.jpg`;
      link.click();
      link.remove();
    })
    .catch(() => toast.error('Failed to generate preview'));
};

export const useHtmlJpg = ({ width, height, scale, filename }: HtmlPreviewConfig) => {
  const ref = useRef<HTMLDivElement>(null);

  const download = () => {
    if (!ref.current) throw new Error('No html element to download preview');
    const div = ref.current.cloneNode(true) as HTMLDivElement;
    div.style.width = `${width}px`;
    div.style.height = height ? `${height}px` : 'auto';
    div.style.position = 'fixed';
    div.style.left = `-${width}px`;
    div.style.top = '0';
    document.body.appendChild(div);

    downloadElementAsJpg(div, filename, scale).finally(() => document.body.removeChild(div));
  };

  return [ref, download] as const;
};

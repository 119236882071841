import { IconButton } from '@app/components/button';
import { FC } from 'react';
import { CaptureDetailsContainer } from '../../containers/capture-details';
import { ModalTrigger } from '@app/components/modal';
import { RestoreCapture } from '../../containers/restore-capture';
import { RemoveCapture } from '../../containers/remove-capture';
import { BaseCaptureButtons } from '../base-capture-buttons/base-capture-buttons';
import { ViewCapture } from '../../views/captures/interfaces';

interface AdminCaptureButtonsProps {
  capture: ViewCapture;
}

export const AdminCaptureButtons: FC<AdminCaptureButtonsProps> = ({ capture }) => {
  const isDeleted = !!capture.deletedAt;

  return (
    <>
      <BaseCaptureButtons capture={capture} />
      <ModalTrigger content={<CaptureDetailsContainer captureId={capture.id} />}>
        {(trigger) => <IconButton icon="details" kind="whitePrimary" onClick={trigger} />}
      </ModalTrigger>
      {isDeleted ? (
        <RestoreCapture>
          {({ restore, loading }) => <IconButton icon="restore" kind="primary" isLoading={loading} onClick={() => restore()} />}
        </RestoreCapture>
      ) : (
        <RemoveCapture>
          {({ remove, loading }) => <IconButton icon="remove" kind="danger" isLoading={loading} onClick={() => remove()} />}
        </RemoveCapture>
      )}
    </>
  );
};

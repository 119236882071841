import { Text } from '@app/layout/text';
import { breakpointsMedia } from '@app/styles/breakpoints';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StatusItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2;
`;

export const StatusLabel = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  ${breakpointsMedia.md} {
    font-size: ${({ theme }) => theme.fontSizes.base};
  }
`;

export const StatusValue = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  ${breakpointsMedia.lg} {
    font-size: ${({ theme }) => theme.fontSizes['3xl']};
  }
`;

import * as z from 'zod';

export const errorMap: z.ZodErrorMap = (error, ctx) => {
  switch (error.code) {
    case z.ZodIssueCode.invalid_type:
      return { message: `Field is required` };
  }

  return { message: ctx.defaultError };
};

import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';
import { ROUTES } from '../../constants/routes.constant';
import { ProtectedPanelWrapper } from './protected-panel.styles';
import { Sidebar } from './sidebar';
import { RouterBoundary } from '@app/components/boundary';
import { useIsLogged } from '@app/panel/hooks/use-current-user';

export const ProtectedPanel = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!useIsLogged()) {
    return <Navigate to={ROUTES.signin} state={{ pathname }} replace />;
  }

  return (
    <ProtectedPanelWrapper>
      <Sidebar />
      <RouterBoundary />
    </ProtectedPanelWrapper>
  );
};

import { MessagesContainer } from '@app/feautres/message/containers/messages';
import { useGalleryId } from '@app/feautres/gallery/contexts/gallery-id.context';
import { useFilters } from '@app/hooks/use-filters';
import { messagesFiltersSchema } from '@app/feautres/message/views/messages-filters/messages-filters-schema';
import { Flex } from '@app/layout/flex';
import { Boundary } from '@app/components/boundary';
import { MessagesFiltersView } from '@app/feautres/message/views/messages-filters';

export const GalleryMessagesSubpage = () => {
  const galleryId = useGalleryId();
  const { filters, update } = useFilters(messagesFiltersSchema);

  return (
    <Flex>
      <MessagesFiltersView filters={filters} onUpdate={update} />
      <Boundary>
        <MessagesContainer filters={{ ...filters, galleryId }} />
      </Boundary>
    </Flex>
  );
};

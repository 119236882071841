import { FC } from 'react';
import { Select } from '@app/components/select';
import { graphql } from '@app/__generated__';
import { useSuspenseQuery } from '@apollo/client';
import { Role } from '@app/__generated__/graphql';

const usersDocument = graphql(`
  query users($filters: UsersFiltersInput) {
    users(filters: $filters) {
      id
      name
    }
  }
`);

interface MultiUserSelectProps {
  value: string[];
  onChange: (value?: string[]) => void;
  role?: Role;
  isError?: boolean;
}

export const MultiUserSelect: FC<MultiUserSelectProps> = ({ value, onChange, role, isError }) => {
  const { data } = useSuspenseQuery(usersDocument, { variables: { filters: { role } }, fetchPolicy: 'cache-first' });

  const options = data.users.map(({ id, name }) => ({ value: id, label: name }));

  const getOptionById = (id: string) => {
    const option = options.find((c) => c.value === id);
    if (!option) throw new Error('Cant find user');
    return option;
  };
  const currentOptions = value ? value.map(getOptionById) : [];

  return (
    <Select
      options={options}
      value={currentOptions}
      onChange={(options) => onChange(options.map((x) => x.value))}
      placeholder="Select users..."
      isError={isError}
      isClearable
      isMulti
    />
  );
};

import * as z from 'zod';

export const galleriesFiltersSchema = z
  .object({
    namePhrase: z.string(),
    galleryTypeId: z.string(),
    my: z.coerce.boolean(),
    deleted: z.coerce.boolean(),
  })
  .partial();

export type GalleriesFilters = z.infer<typeof galleriesFiltersSchema>;

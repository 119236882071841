import { Flex } from '@app/layout/flex';
import { useSuspenseQuery } from '@apollo/client';
import { graphql } from '@app/__generated__';
import { StatusThumb, GalleryStatusWrapper, StatusCategory, StatusGrid } from './gallery-status.styles';
import { FC } from 'react';
import { CapturesViewer } from '@app/feautres/capture/components/captures-viewer/captures-viewer';
import notLoadedImg from '@app/assets/not-loaded.svg';
import { Tooltip } from 'react-tooltip';
import { GalleryStatusItem } from './item';

const galleryStatusDocument = graphql(`
  query galleryStatus($galleryId: UUID!) {
    messagesSent: messagesCount(filters: { galleryId: $galleryId, status: SENT })
    messagesError: messagesCount(filters: { galleryId: $galleryId, status: ERROR })
    messagesWaiting: messagesCount(filters: { galleryId: $galleryId, status: WAITING })
    capturesWaiting: capturesCount(filters: { galleryId: $galleryId, status: CREATED })
    capturesError: capturesCount(filters: { galleryId: $galleryId, status: ERROR })
    capturesProcessed: capturesCount(filters: { galleryId: $galleryId, status: PROCESSED })
    captures: pageCaptures(page: 1, limit: 3, filters: { galleryId: $galleryId, status: PROCESSED }) {
      items {
        id
        shortId
        uploadFilename
        status
        deletedAt
        file {
          path
        }
        preview {
          path
        }
        thumbnail {
          path
        }
      }
    }
  }
`);

interface GalleryStatusProps {
  galleryId: string;
}

export const GalleryStatus: FC<GalleryStatusProps> = ({ galleryId }) => {
  const { data } = useSuspenseQuery(galleryStatusDocument, { variables: { galleryId } });

  const ttId = 'gallery-status-' + galleryId;

  const getCapturesUrl = (status: string) => `/galleries/${galleryId}/captures/page/1?status=${status}`;
  const getMessagesUrl = (status: string) => `/galleries/${galleryId}/messages/page/1?status=${status}`;

  return (
    <GalleryStatusWrapper>
      <StatusCategory center>Captures</StatusCategory>
      <StatusGrid $count={3}>
        <GalleryStatusItem
          url={getCapturesUrl('PROCESSED')}
          color="success"
          label="PROCESSED"
          value={data.capturesProcessed}
          ttId={ttId}
          ttContent="Uploaded and ready captures"
        />
        <GalleryStatusItem
          url={getCapturesUrl('ERROR')}
          color="danger"
          label="ERROR"
          value={data.capturesError}
          ttId={ttId}
          ttContent="Uploaded with error while processing (damaged file)"
        />
        <GalleryStatusItem
          url={getCapturesUrl('CREATED')}
          color="warning"
          label="WAITING"
          value={data.capturesWaiting}
          ttId={ttId}
          ttContent="Not uploaded captures with waiting messages"
        />
      </StatusGrid>
      <StatusCategory center>Messages</StatusCategory>
      <StatusGrid $count={3}>
        <GalleryStatusItem
          url={getMessagesUrl('SENT')}
          color="success"
          label="SENT"
          value={data.messagesSent}
          ttId={ttId}
          ttContent="Sent messages"
        />
        <GalleryStatusItem
          url={getMessagesUrl('ERROR')}
          color="danger"
          label="ERROR"
          value={data.messagesError}
          ttId={ttId}
          ttContent="Not sent messages due to an error"
        />
        <GalleryStatusItem
          url={getMessagesUrl('WAITING')}
          color="warning"
          label="WAITING"
          value={data.messagesWaiting}
          ttId={ttId}
          ttContent="Messages waiting to be sent (no processed capture)"
        />
      </StatusGrid>
      <CapturesViewer captures={data.captures.items}>
        {({ handleChange }) => (
          <Flex row gap={5} justifyContent="center" alignItems="flex-start">
            {data.captures.items.map((capture) => (
              <StatusThumb key={capture.id} src={capture.thumbnail?.path || notLoadedImg} onClick={() => handleChange(capture)} />
            ))}
          </Flex>
        )}
      </CapturesViewer>
      <Tooltip id={ttId} />
    </GalleryStatusWrapper>
  );
};

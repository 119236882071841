import { FC } from 'react';
import { Flex } from '@app/layout/flex';
import { RouterPagination } from '@app/components/pagination/router-pagination';
import { MessagesView } from '../../views/messages-view';
import { useRouterPage } from '@app/hooks/use-router-page';
import { graphql } from '@app/__generated__';
import { MessagesFiltersInput } from '@app/__generated__/graphql';
import { useSuspenseQuery } from '@apollo/client';
import { useBreakpoint } from '@app/hooks/use-breakpoint';

const messagesWithCaptureDocument = graphql(`
  query messagesWithCapture($page: Int!, $limit: Int!, $filters: MessagesFiltersInput) {
    messages(page: $page, limit: $limit, filters: $filters) {
      count
      pagesCount
      items {
        id
        contact
        type
        status
        sentAt
        errorMessage
        capture {
          id
          uploadFilename
          thumbnail {
            path
          }
          gallery {
            name
          }
        }
      }
    }
  }
`);

interface MessagesProps {
  filters?: MessagesFiltersInput;
}

export const MessagesContainer: FC<MessagesProps> = ({ filters }) => {
  const page = useRouterPage();

  const { data } = useSuspenseQuery(messagesWithCaptureDocument, {
    variables: { page, limit: 20, filters },
  });

  const { items, pagesCount } = data.messages;

  const isDesktop = useBreakpoint('lg');

  return (
    <Flex>
      {!isDesktop && !!items.length && <RouterPagination pagesCount={pagesCount} />}
      <MessagesView messages={items} />
      <RouterPagination pagesCount={pagesCount} />
    </Flex>
  );
};

import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const CaptureDetailsViewPreview = styled.div`
  align-self: center;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpointsMedia.md} {
    height: 350px;
  }
`;

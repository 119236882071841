import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { ZodObject } from 'zod';
import * as z from 'zod';

export const useFilters = <T extends ZodObject<any>>(schema: T) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useMemo(() => {
    const data: Record<string, string | undefined> = {};
    for (const property of Object.keys(schema.shape)) {
      const value = searchParams.get(property);
      if (value) data[property] = value;
    }
    return schema.parse(data) as z.infer<typeof schema>;
  }, [searchParams, schema]);

  const update = useCallback(
    (values: Partial<Record<keyof z.infer<typeof schema>, string | null>>) => {
      for (const [key, value] of Object.entries(values)) {
        if (value === null) searchParams.delete(key);
        else searchParams.set(key, value as string);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return { filters, update };
};

import { downloadElementAsJpg } from '@app/hooks/use-html-jpg';

export const downloadEmailPreview = (html: string) => {
  const iframe = document.createElement('iframe');

  iframe.style.width = `450px`;
  iframe.style.position = 'fixed';
  iframe.style.left = `-450px`;
  iframe.style.top = '0';
  document.body.appendChild(iframe);

  const iframeDocument = iframe.contentDocument!;
  iframeDocument.open();
  iframeDocument.write(`
    <!DOCTYPE html>
    <html>
    ${html}
    </html>
  `);
  iframeDocument.close();

  const renderEl = iframeDocument?.body;
  if (!renderEl) return;

  downloadElementAsJpg(renderEl, 'email_preview').finally(() => document.body.removeChild(iframe));
};

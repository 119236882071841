import styled, { css } from 'styled-components';

export const UploadAreaWrapper = styled.div<{ $hover: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 70px;
  min-width: 200px;
  border: 2px dashed ${({ theme }) => theme.colors.grayDark};

  ${({ $hover }) =>
    $hover &&
    css`
      background: white;
    `}
`;

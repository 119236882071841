import { FC } from 'react';
import { Select } from '@app/components/select';
import { graphql } from '@app/__generated__';
import { useSuspenseQuery } from '@apollo/client';
import { Role } from '@app/__generated__/graphql';

const usersDocument = graphql(`
  query users($filters: UsersFiltersInput) {
    users(filters: $filters) {
      id
      name
    }
  }
`);

interface UserSelectProps {
  value?: string;
  onChange: (value: string | null) => void;
  role?: Role;
  isError?: boolean;
}

export const UserSelect: FC<UserSelectProps> = ({ value, onChange, role, isError }) => {
  const { data } = useSuspenseQuery(usersDocument, { variables: { filters: { role } }, fetchPolicy: 'cache-first' });

  const options = data.users.map(({ id, name }) => ({ value: id, label: name }));

  const currentOption = options.find((c) => c.value === value) || null;

  return (
    <Select
      options={options}
      value={currentOption}
      onChange={(option) => onChange(option?.value || null)}
      placeholder="Select user..."
      isError={isError}
      isClearable
    />
  );
};

import { FC } from 'react';
import { MessagesItem } from './messages-item';
import { PageInfo } from '@app/layout/page-info';
import { ViewMessage } from './interfaces/message.interface';
import { ResponsiveTable } from '@app/layout/responsive-table';

interface MessagesViewProps {
  messages: ViewMessage[];
}

export const MessagesView: FC<MessagesViewProps> = ({ messages }) => {
  if (!messages.length) return <PageInfo>Not found any messages...</PageInfo>;

  return (
    <ResponsiveTable>
      <tbody>
        {messages.map((message) => (
          <MessagesItem key={message.id} message={message} />
        ))}
      </tbody>
    </ResponsiveTable>
  );
};

import { Link, useLocation, useNavigate, useResolvedPath } from 'react-router-dom';
import { Button } from '@app/components/button';
import { GalleryPageNavWrapper } from './gallery-page-nav.styles';
import { Select } from '@app/components/select';
import { useBreakpoint } from '@app/hooks/use-breakpoint';

export const GalleryPageNav = () => {
  const tabs = [
    { value: 'captures', label: 'Captures' },
    { value: 'status', label: 'Status' },
    { value: 'summary', label: 'Summary' },
    { value: 'config', label: 'Config' },
    { value: 'template', label: 'Template' },
    { value: 'messages', label: 'Messages' },
    { value: 'stats', label: 'Stats' },
  ];
  const basePath = useResolvedPath('').pathname;
  const currentTabKey = useLocation().pathname.replace(basePath, '').split('/')[1];
  const currentTab = tabs.find((c) => c.value === currentTabKey);

  const isMobile = !useBreakpoint('md');
  const navigate = useNavigate();

  if (isMobile) return <Select value={currentTab} options={tabs} onChange={(data) => data && navigate(data.value)} />;
  return (
    <GalleryPageNavWrapper>
      {tabs.map((tab) => (
        <Link key={tab.value} to={tab.value}>
          <Button size="small" kind={tab === currentTab ? 'primary' : 'gray'}>
            {tab.label}
          </Button>
        </Link>
      ))}
    </GalleryPageNavWrapper>
  );
};

import { DefaultValues, useForm } from 'react-hook-form';
import { FC } from 'react';
import { GalleryTypeFields, galleryTypeResolver } from './gallery-type-resolver';
import { Input } from '@app/components/input';
import { Button } from '@app/components/button';
import { FormField } from '@app/layout/form-field';
import { LayoutItem } from '@app/layout/layout-item';
import { Flex } from '@app/layout/flex';

interface GalleryTypeFormViewProps {
  data?: DefaultValues<GalleryTypeFields>;
  onSubmit: (data: GalleryTypeFields) => void;
  loading: boolean;
  isCreate?: boolean;
}

export const GalleryTypeFormView: FC<GalleryTypeFormViewProps> = ({ data, onSubmit, loading, isCreate }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<GalleryTypeFields>({
    defaultValues: Object.assign({}, data),
    resolver: galleryTypeResolver,
    mode: 'onBlur',
    shouldUnregister: true,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap={70}>
        <FormField title="Name" error={errors.name}>
          <Input {...register('name')} autoFocus />
        </FormField>
        <LayoutItem>
          <Button icon="save" type="submit" isLoading={loading}>
            {isCreate ? 'Create' : 'Save'}
          </Button>
        </LayoutItem>
      </Flex>
    </form>
  );
};

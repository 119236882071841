import { ChangeEvent, FC, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Button } from '@app/components/button';
import { Spinner } from '@app/layout/spinner';
import { UploadAreaWrapper } from './upload-area.styles';

interface UploadAreaProps {
  onSelect: (file: File) => void;
  isUploading?: boolean;
  accept?: string[];
}

export const UploadArea: FC<UploadAreaProps> = ({ onSelect, isUploading, accept = ['image/png', 'image/jpeg'] }) => {
  const ref = useRef<HTMLInputElement>(null);
  const chooseFiles = () => ref.current?.click();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) onSelect(file);
  };

  const [{ isReady }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop(item: { files: File[] }) {
      if (item.files?.[0]) onSelect(item.files[0]);
    },
    canDrop(item: any) {
      if (!item.items) return false;
      const arr = Array.from(item.items) as { type: string }[];
      return arr.every((item) => accept.includes(item.type));
    },
    collect: (monitor) => {
      return {
        isReady: monitor.canDrop() && monitor.isOver(),
      };
    },
  }));

  return (
    <UploadAreaWrapper ref={drop} $hover={isReady}>
      {isReady && 'Release...'}
      {!isUploading && !isReady && (
        <Button onClick={chooseFiles} type="button" size="small">
          Choose...
        </Button>
      )}
      {isUploading && <Spinner />}
      <input ref={ref} style={{ display: 'none' }} type="file" onChange={handleChange} accept={accept.join(',')} />
    </UploadAreaWrapper>
  );
};

import { breakpointsMedia } from '@app/styles/breakpoints';
import { css } from 'styled-components';

export const calendarCss = css`
  .rdrCalendarWrapper {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    box-sizing: border-box;

    .rdrMonthsHorizontal {
      justify-content: center;
    }

    .rdrMonth {
      width: 100%;
    }

    ${breakpointsMedia.md} {
      .rdrMonth {
        width: 50%;
      }
    }
  }
`;

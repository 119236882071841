import { FC, useCallback, useState } from 'react';
import { SearchInput } from '@app/components/input/search-input';
import { MessagesFiltersViewWrapper } from './messages-filters-view.styles';
import { Select } from '@app/components/select';
import { MessageStatus } from '@app/__generated__/graphql';
import { MessagesFilters } from './messages-filters-schema';
import { useBreakpoint } from '@app/hooks/use-breakpoint';
import { Button } from '@app/components/button';
import { FiltersViewProps } from '@app/interfaces/filters-view-props.interface';

export const MessagesFiltersView: FC<FiltersViewProps<MessagesFilters>> = ({ filters, onUpdate }) => {
  const messageOptions: { value: MessageStatus; label: string }[] = [
    { value: MessageStatus.Sent, label: 'SENT' },
    { value: MessageStatus.Error, label: 'ERROR' },
    { value: MessageStatus.Waiting, label: 'WAITING' },
  ];

  const currentOption = messageOptions.find((c) => c.value === filters.status) || null;

  const handleContactChange = useCallback(
    (value: string) => {
      onUpdate({ contactPhrase: value });
    },
    [onUpdate],
  );

  const [isActive, setIsActive] = useState(false);
  const isDesktop = useBreakpoint('md');
  if (!isActive && !isDesktop) return <Button onClick={() => setIsActive(true)}>Filters</Button>;

  return (
    <MessagesFiltersViewWrapper>
      <SearchInput initialValue={filters.contactPhrase} onSearch={handleContactChange} />
      <Select
        placeholder="Select status..."
        options={messageOptions}
        value={currentOption}
        onChange={(data) => onUpdate({ status: data?.value || null })}
        isClearable
      />
    </MessagesFiltersViewWrapper>
  );
};

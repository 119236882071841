import { useNavigate } from 'react-router';
import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { useGalleryId } from '@app/feautres/gallery/contexts/gallery-id.context';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { graphql } from '@app/__generated__';
import { TemplateFormView } from '@app/feautres/template/views/template-form';
import { TemplateFields } from '@app/feautres/template/views/template-form/template-resolver';

const templateDocument = graphql(`
  query template($galleryId: UUID!) {
    template(galleryId: $galleryId) {
      email {
        customMjml {
          id
          mjml
        }
        subject
        fromName
        logoUrl
        logoWidth
        logoPadding
        headerText
        topText
        bottomText
        buttonText
        textColor
        color
        bgColor
        contentBgColor
        showBottomImg
      }
      sms {
        sender
        template
      }
      qr {
        logoUrl
        logoWidth
        headerText
        inputText
        buttonText
        successText
        textColor
        color
        bgColor
        showBottomImg
      }
    }
  }
`);

const saveTemlateDocument = graphql(`
  mutation saveTemplate($galleryId: UUID!, $data: SaveTemplateInput!) {
    saveTemplate(galleryId: $galleryId, data: $data)
  }
`);

export const GalleryTemplateSubpage = () => {
  const galleryId = useGalleryId();
  const { data } = useSuspenseQuery(templateDocument, { variables: { galleryId }, fetchPolicy: 'network-only' });

  const navigate = useNavigate();
  const [save, { loading }] = useMutation(saveTemlateDocument, {
    onCompleted: () => navigate('../../'),
    onError: getErrorHandler(),
  });

  const handleSubmit = (data: TemplateFields) => {
    save({ variables: { data, galleryId } });
  };

  return <TemplateFormView data={data.template} onSubmit={handleSubmit} loading={loading} />;
};

import { FC, ReactNode } from 'react';
import { InfoGroupWrapper } from './info-group.styles';
import { Text } from '@app/layout/text';

interface InfoGroupProps {
  header: string;
  value: string | ReactNode;
}

export const InfoGroup: FC<InfoGroupProps> = ({ header, value }) => {
  return (
    <InfoGroupWrapper>
      <Text size="xs">{header}</Text>
      <Text>{value}</Text>
    </InfoGroupWrapper>
  );
};

import styled from 'styled-components';

export const QrLandingPreviewWrapper = styled.div<{ $bgColor: string }>`
  width: 390px;
  max-width: 90vw;
  min-height: 650px;
  position: relative;
  background: ${({ $bgColor }) => $bgColor};
  margin: 0 auto;
`;

import { FC, useTransition } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Pagination } from './pagination';
import { useRouterPage } from '@app/hooks/use-router-page';

interface RouterPaginationProps {
  pagesCount: number;
}

export const RouterPagination: FC<RouterPaginationProps> = ({ pagesCount }) => {
  const page = useRouterPage();
  const [searchParams] = useSearchParams();

  const [isPending, startTransition] = useTransition();

  const navigate = useNavigate();
  const loadPage = (page: number) => {
    startTransition(() => navigate({ pathname: `../page/${page}`, search: searchParams.toString() }));
  };

  return <Pagination pagesCount={pagesCount} isLoading={isPending} page={page} onChange={loadPage} />;
};

import { FC } from 'react';
import { PageInfo } from '@app/layout/page-info';
import { ViewGalleryType } from './interfaces/view-gallery-type.interface';
import { GalleryTypesItem } from './gallery-types-item';
import { ResponsiveTable } from '@app/layout/responsive-table';

interface GalleryTypesViewProps {
  galleryTypes: ViewGalleryType[];
}

export const GalleryTypesView: FC<GalleryTypesViewProps> = ({ galleryTypes }) => {
  if (!galleryTypes.length) return <PageInfo>Not found any gallery types...</PageInfo>;

  return (
    <ResponsiveTable>
      <tbody>
        {galleryTypes.map((galleryType) => (
          <GalleryTypesItem key={galleryType.id} galleryType={galleryType} />
        ))}
      </tbody>
    </ResponsiveTable>
  );
};

import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { useCaptureId } from '@app/feautres/capture/contexts/capture-id.context';
import { graphql } from '@app/__generated__';
import { useMutation } from '@apollo/client';
import { CapturesDocument } from '@app/__generated__/graphql';
import { useConfirm } from '@app/feautres/confirm/context/confirm-context';
import { FC, ReactNode } from 'react';

const deleteCaptureDocument = graphql(`
  mutation deleteCapture($captureId: UUID!) {
    deleteCapture(captureId: $captureId)
  }
`);

interface RemoveCaptureProps {
  children: (data: { remove: () => void; loading: boolean }) => ReactNode;
}

export const RemoveCapture: FC<RemoveCaptureProps> = ({ children }) => {
  const captureId = useCaptureId();

  const [remove, { loading }] = useMutation(deleteCaptureDocument, {
    variables: { captureId },
    refetchQueries: [CapturesDocument],
    onError: getErrorHandler(),
  });

  const confirmRemove = useConfirm({
    question: `Are you sure you want to delete item?`,
    actionName: 'Delete',
    cb: remove,
  });

  return children({ remove: confirmRemove, loading });
};

import { Text } from '@app/layout/text';
import { breakpointsMedia } from '@app/styles/breakpoints';
import styled from 'styled-components';

export const GalleriesItemName = styled(Text)`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.xs};

  ${breakpointsMedia.lg} {
    text-align: left;
  }
`;

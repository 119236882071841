import { FC } from 'react';
import { Flex } from '@app/layout/flex';
import { Text } from '@app/layout/text';
import { GalleryTypeFields } from '../../views/gallery-type-form/gallery-type-resolver';
import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { graphql } from '@app/__generated__';
import { useMutation } from '@apollo/client';
import { GalleryTypesDocument } from '@app/__generated__/graphql';
import { GalleryTypeFormView } from '../../views/gallery-type-form';

const createGalleryTypeDocument = graphql(`
  mutation createGalleryType($data: CreateGalleryTypeInput!) {
    galleryType: createGalleryType(data: $data) {
      id
    }
  }
`);

interface GalleryTypeCreateProps {
  onCreated?: (galleryTypeId: string) => void;
}

export const GalleryTypeCreateContainer: FC<GalleryTypeCreateProps> = ({ onCreated }) => {
  const [create, { loading }] = useMutation(createGalleryTypeDocument, {
    onCompleted: ({ galleryType }) => onCreated?.(galleryType.id),
    refetchQueries: [GalleryTypesDocument],
    onError: getErrorHandler(),
  });

  const handleSubmit = (data: GalleryTypeFields) => {
    create({ variables: { data } });
  };

  return (
    <Flex gap={40}>
      <Text size="lg">Create gallery type</Text>
      <GalleryTypeFormView onSubmit={handleSubmit} loading={loading} />
    </Flex>
  );
};

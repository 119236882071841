import styled, { css } from 'styled-components';
import { breakpoints, breakpointsMedia } from '@app/styles/breakpoints';

export const ResponsiveTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  @media screen and (max-width: ${breakpoints.lg}) {
    & thead,
    & tbody,
    & th,
    & td,
    & tr {
      display: block;
    }
  }
`;

export const ResponsiveTableRow = styled.tr<{ $clickable?: boolean }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};

  & td {
    cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
    padding: 5px 0px;
  }

  & td:first-child {
    margin-top: 10px;
  }

  & td:last-child {
    margin-bottom: 10px;
  }

  ${breakpointsMedia.lg} {
    & td {
      padding: 10px 10px;
    }
  }

  &:last-child {
    border-bottom: 0;
  }
`;

export const ResponsiveTableCell = styled.td<{ $full?: boolean; $wrap?: boolean }>`
  white-space: normal;
  text-align: center;
  white-space: ${({ $wrap }) => ($wrap ? 'normal' : 'nowrap')};

  ${({ $full }) =>
    $full &&
    css`
      width: 100%;
    `}
`;

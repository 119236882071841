import { FC } from 'react';
import { SmsPreviewMessage, SmsPreviewPhone, SmsPreviewSender, SmsPreviewWrapper } from './sms-preview.styles';
import { TemplateFields } from '../template-resolver';
import { getCaptureUrl } from '@app/utils/links.util';
import { Flex } from '@app/layout/flex';
import { Button } from '@app/components/button';
import { useHtmlJpg } from '@app/hooks/use-html-jpg';

interface SmsPreviewProps {
  template: TemplateFields;
}

export const SmsPreview: FC<SmsPreviewProps> = ({ template }) => {
  const html = template.sms.template
    .replace('{LINK}', `<span>${getCaptureUrl('x37cGhq15Juvro1BQqrAc7')}</span>`)
    .replaceAll('\n', '<br/>');

  const [ref, download] = useHtmlJpg({ filename: 'sms-preview', width: 800, height: 1000 });

  return (
    <Flex alignItems="center">
      <Button type="button" icon="download" size="small" onClick={() => download()}>
        Download preview
      </Button>
      <SmsPreviewWrapper>
        <SmsPreviewPhone ref={ref}>
          <SmsPreviewSender>{template.sms.sender}</SmsPreviewSender>
          <SmsPreviewMessage dangerouslySetInnerHTML={{ __html: html }}></SmsPreviewMessage>
        </SmsPreviewPhone>
      </SmsPreviewWrapper>
    </Flex>
  );
};

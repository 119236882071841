import { ROUTES } from '@app/panel/constants/routes.constant';
import { CreateGalleryPage } from '@app/panel/pages/create-gallery';
import { GalleriesPage } from '@app/panel/pages/galleries';
import { GalleryPage } from '@app/panel/pages/gallery';
import { GalleryBaseEditSubpage } from '@app/panel/pages/gallery/subpages/base-edit';
import { GalleryCapturesSubpage } from '@app/panel/pages/gallery/subpages/captures';
import { GalleryMessagesSubpage } from '@app/panel/pages/gallery/subpages/messages';
import { GalleryStatsSubpage } from '@app/panel/pages/gallery/subpages/stats';
import { GalleryStatusSubpage } from '@app/panel/pages/gallery/subpages/status';
import { GallerySummarySubpage } from '@app/panel/pages/gallery/subpages/summary';
import { GalleryTemplateSubpage } from '@app/panel/pages/gallery/subpages/template';
import { Navigate, RouteObject } from 'react-router-dom';
import { paginatedRoute } from '../utils/paginated-route.util';

export const galleriesRoutes: RouteObject = paginatedRoute({
  path: ROUTES.galleries,
  element: <GalleriesPage />,
  children: [
    {
      path: 'new',
      element: <CreateGalleryPage />,
    },
    {
      path: ':galleryId',
      element: <GalleryPage />,
      children: [
        paginatedRoute({ path: 'captures', element: <GalleryCapturesSubpage /> }),
        paginatedRoute({ path: 'messages', element: <GalleryMessagesSubpage /> }),
        { path: 'summary', element: <GallerySummarySubpage /> },
        { path: 'config', element: <GalleryBaseEditSubpage /> },
        { path: 'template', element: <GalleryTemplateSubpage /> },
        { path: 'stats', element: <GalleryStatsSubpage /> },
        { path: 'status', element: <GalleryStatusSubpage /> },
        { path: '*?', element: <Navigate to="captures" replace /> },
      ],
    },
  ],
});

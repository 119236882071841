import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const signinSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});

export type SigninFields = z.infer<typeof signinSchema>;

export const signinResolver = zodResolver(signinSchema);

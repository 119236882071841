import { RouterProvider } from 'react-router-dom';
import { router } from './navigation/router';
import { ApolloProvider } from '@apollo/client';
import { userClient } from '@app/apollo/user-client';
import { ThemeProvider } from 'styled-components';
import { theme } from '@app/styles/theme';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ConfirmProvider } from '@app/feautres/confirm/providers/confirm-provider';
import { ToastsContainer } from '@app/components/toasts-container';
import { GlobalStyles } from '@app/styles/global';
import { PanelGlobalStyles } from './styles/global';

export const App = () => {
  return (
    <ApolloProvider client={userClient}>
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <ConfirmProvider>
            <ToastsContainer />
            <GlobalStyles />
            <PanelGlobalStyles />
            <RouterProvider router={router} />
          </ConfirmProvider>
        </DndProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

import dayjs from 'dayjs';
import { Tooltip } from 'react-tooltip';
import { Text } from '@app/layout/text';
import { Flex } from '@app/layout/flex';
import { Button } from '@app/components/button';
import { Link, useNavigate } from 'react-router-dom';
import { ViewGallery } from '../interfaces/view-gallery.interface';
import { RestoreGallery } from '@app/feautres/gallery/containers/restore-gallery';
import { GalleryIdProvider } from '@app/feautres/gallery/providers/gallery-id-provider';
import { GalleriesItemName } from './galleries-item.styles';
import { useRef } from 'react';
import { ResponsiveTableCell, ResponsiveTableRow } from '@app/layout/responsive-table';
import { getRelativeTime, getTimeColor } from './galleries-item.utils';

interface GalleriesItemProps {
  gallery: ViewGallery;
}

export const GalleriesItem = ({ gallery }: GalleriesItemProps) => {
  const navigate = useNavigate();
  const actionsRef = useRef<HTMLTableCellElement>(null);

  return (
    <GalleryIdProvider galleryId={gallery.id}>
      <ResponsiveTableRow
        $clickable={!gallery.removalDate}
        onClick={(e) => {
          if (gallery.removalDate) return;
          if (actionsRef.current?.contains(e.target as Node)) return;
          navigate(`../${gallery.id}`);
        }}
      >
        <ResponsiveTableCell $wrap $full>
          <GalleriesItemName>{gallery.name}</GalleriesItemName>
        </ResponsiveTableCell>
        <ResponsiveTableCell>
          <Text center size="xxs">
            {gallery.type.name}
          </Text>
        </ResponsiveTableCell>
        <ResponsiveTableCell>
          <Text center size="xxs" color="grayDark">
            {dayjs(gallery.startsAt).format('DD/MM/YYYY')} - {dayjs(gallery.endsAt).format('DD/MM/YYYY')}
          </Text>
        </ResponsiveTableCell>
        <ResponsiveTableCell>
          <Text center size="xs">
            {gallery.capturesCount} items
          </Text>
        </ResponsiveTableCell>
        {!gallery.removalDate ? (
          <>
            <ResponsiveTableCell>
              <Text center size="xs" color={getTimeColor(new Date(gallery.startsAt), new Date(gallery.endsAt))}>
                {getRelativeTime(new Date(gallery.startsAt), new Date(gallery.endsAt))}
              </Text>
            </ResponsiveTableCell>
          </>
        ) : (
          <ResponsiveTableCell>
            <Text center color="danger" size="xs">
              {dayjs(gallery.removalDate).diff(dayjs().hour(0), 'days')} days left
            </Text>
          </ResponsiveTableCell>
        )}
        <ResponsiveTableCell ref={actionsRef}>
          <Flex gap={0} row justifyContent="center">
            {!gallery.removalDate ? (
              <>
                <Link to={`../${gallery.id}/template`}>
                  <Button
                    size="small"
                    kind="white"
                    icon="template"
                    data-tooltip-id="galleries-item"
                    data-tooltip-content="Template config"
                  />
                </Link>
                <Link to={`../${gallery.id}/summary`}>
                  <Button size="small" kind="white" icon="eye" data-tooltip-id="galleries-item" data-tooltip-content="Summary" />
                </Link>
              </>
            ) : (
              <RestoreGallery>
                {({ restore, loading }) => (
                  <Button kind="primary" size="small" icon="restore" isLoading={loading} onClick={restore} />
                )}
              </RestoreGallery>
            )}
          </Flex>
          <Tooltip id="galleries-item" />
        </ResponsiveTableCell>
      </ResponsiveTableRow>
    </GalleryIdProvider>
  );
};

import { useGalleryId } from '@app/feautres/gallery/contexts/gallery-id.context';
import { GalleryStatus } from '@app/feautres/gallery/containers/gallery-status';
import { GalleryStatusSubpageWrapper } from './gallery-status-subpage.styles';

export const GalleryStatusSubpage = () => {
  const galleryId = useGalleryId();

  return (
    <GalleryStatusSubpageWrapper>
      <GalleryStatus galleryId={galleryId} />
    </GalleryStatusSubpageWrapper>
  );
};

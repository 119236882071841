import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { TemplateFields } from '../template-resolver';
import { Textarea } from '@app/components/input';
import { FormField } from '@app/layout/form-field';
import { Flex } from '@app/layout/flex';
import { SmsSenderFormField } from './sms-sender';

export const SmsFormPart: FC = () => {
  const {
    register,
    formState: { errors },
    control,
    watch,
  } = useFormContext<TemplateFields>();

  const values = watch();

  return (
    <Flex>
      <SmsSenderFormField control={control} />
      <FormField
        title="Template"
        error={errors.sms?.template}
        bottom="* {LINK} will be replaced by real link"
        bottomRight={`${values.sms.template.length} / 250`}
      >
        <Textarea maxLength={250} {...register('sms.template')} />
      </FormField>
    </Flex>
  );
};

import dayjs from 'dayjs';

export const getRelativeTime = (startsAt: Date, endsAt: Date) => {
  const nowDate = new Date();
  if (new Date() < startsAt) {
    const fromNow = dayjs(startsAt).from(dayjs().hour(0));
    if (fromNow.includes('hour')) return 'in a day';
    return fromNow;
  }
  if (nowDate > endsAt) return 'Closed';
  return 'In progress';
};

export const getTimeColor = (startsAt: Date, endsAt: Date) => {
  const nowDate = new Date();
  if (nowDate < startsAt) return 'success';
  if (nowDate > endsAt) return 'danger';
  return 'warning';
};

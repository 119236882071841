import { GallerySummarySubpageGrid } from './gallery-summary-subpage.styles';
import { Flex } from '@app/layout/flex';
import { Button } from '@app/components/button';
import { Icon } from '@app/components/icon';
import { formatBytes, formatDate } from '@app/utils/format.util';
import { getCustomerUrl } from '@app/utils/links.util';
import { InfoGroup } from '@app/layout/info-group';
import { CopyField } from '@app/components/copy-field';
import { useGalleryId } from '@app/feautres/gallery/contexts/gallery-id.context';
import { useSuspenseQuery } from '@apollo/client';
import { graphql } from '@app/__generated__';
import { RemoveGallery } from '@app/feautres/gallery/containers/remove';
import { DuplicateGallery } from '@app/feautres/gallery/containers/duplicate';
import { DownloadCapturesZip } from '@app/feautres/capture/containers/download-captures-zip';

const galleryDocument = graphql(`
  query gallery($galleryId: UUID!) {
    gallery(galleryId: $galleryId) {
      id
      slug
      password
      startsAt
      endsAt
      capturesCount
    }
  }
`);

export const GallerySummarySubpage = () => {
  const galleryId = useGalleryId();
  const { data } = useSuspenseQuery(galleryDocument, { variables: { galleryId } });

  const { gallery } = data;
  const customerAccess = `${getCustomerUrl(gallery.slug)}\nPassword: ${gallery.password}`;

  return (
    <Flex gap={80}>
      <GallerySummarySubpageGrid>
        <InfoGroup header="Starts at" value={formatDate(gallery.startsAt)} />
        <InfoGroup header="Ends at" value={formatDate(gallery.endsAt)} />
      </GallerySummarySubpageGrid>
      <GallerySummarySubpageGrid>
        <InfoGroup header="Items count" value={gallery.capturesCount} />
        <InfoGroup header="Customer access" value={<CopyField value={customerAccess} />} />
      </GallerySummarySubpageGrid>
      <Flex alignItems="center">
        <DownloadCapturesZip filename={gallery.slug} filters={{ galleryId: gallery.id }}>
          {({ download, loading, size }) => (
            <Button onClick={() => download()} isLoading={loading}>
              <Icon name="download" />
              Download all <b>{formatBytes(size)}</b>
            </Button>
          )}
        </DownloadCapturesZip>
        <DownloadCapturesZip
          filename={`${gallery.slug}-marketing`}
          filters={{ galleryId: gallery.id, metadata: { picsaneMarketingConsent: true } }}
        >
          {({ download, loading, size }) => (
            <Button size="small" onClick={() => download()} isLoading={loading}>
              <Icon name="download" />
              Download picsane marketing <b>{formatBytes(size)}</b>
            </Button>
          )}
        </DownloadCapturesZip>
        <DuplicateGallery>
          {({ duplicate, loading }) => (
            <Button size="small" icon="copy" isLoading={loading} onClick={() => duplicate()}>
              Duplicate gallery
            </Button>
          )}
        </DuplicateGallery>
        <RemoveGallery>
          {({ remove, loading }) => (
            <Button size="small" kind="danger" icon="remove" isLoading={loading} onClick={() => remove()}>
              Remove gallery
            </Button>
          )}
        </RemoveGallery>
      </Flex>
    </Flex>
  );
};

import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const gallerySchema = z.object({
  name: z.string().min(3).max(80),
  galleryTypeId: z.string().uuid(),
  techniciansIds: z.array(z.string().uuid()),
  contacts: z.array(z.string().email()),
  eventManagerId: z.string().uuid(),
  slug: z
    .string()
    .min(3)
    .regex(/^[^\s!?/.*#|]+$/),
  thumbnailPosition: z.number().min(0).max(100),
  startsAt: z.string(),
  endsAt: z.string(),
});

const statsConfigSchema = z.object({
  isPeopleCounterActive: z.boolean(),
});

const galleryConfigSchema = z.object({
  gallery: gallerySchema,
  statsConfig: statsConfigSchema,
});

export type GalleryConfigFields = z.infer<typeof galleryConfigSchema>;

export const galleryConfigResolver = zodResolver(galleryConfigSchema);

import { FC } from 'react';
import { GalleryTypeFields } from '../../views/gallery-type-form/gallery-type-resolver';
import { Flex } from '@app/layout/flex';
import { Text } from '@app/layout/text';
import { getErrorHandler } from '@app/utils/apollo-errors.util';
import { graphql } from '@app/__generated__';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { GalleryTypeFormView } from '../../views/gallery-type-form';

const galleryTypeDocument = graphql(`
  query galleryType($galleryTypeId: UUID!) {
    galleryType(galleryTypeId: $galleryTypeId) {
      id
      name
    }
  }
`);

const updateGalleryTypeDocument = graphql(`
  mutation updateGalleryType($galleryTypeId: UUID!, $data: UpdateGalleryTypeInput!) {
    updateGalleryType(galleryTypeId: $galleryTypeId, data: $data) {
      id
      name
    }
  }
`);

interface GalleryTypeEditProps {
  galleryTypeId: string;
  onSaved?: () => void;
}

export const GalleryTypeEdit: FC<GalleryTypeEditProps> = ({ galleryTypeId, onSaved }) => {
  const { data } = useSuspenseQuery(galleryTypeDocument, { variables: { galleryTypeId }, fetchPolicy: 'network-only' });

  const [update, { loading }] = useMutation(updateGalleryTypeDocument, {
    onCompleted: () => onSaved?.(),
    onError: getErrorHandler(),
  });

  const handleSubmit = (data: GalleryTypeFields) => {
    update({ variables: { galleryTypeId, data } });
  };
  return (
    <Flex gap={40}>
      <Text size="lg">Edit gallery type</Text>
      <GalleryTypeFormView data={data.galleryType} onSubmit={handleSubmit} loading={loading} />
    </Flex>
  );
};

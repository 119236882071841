import { useSuspenseQuery } from '@apollo/client';
import { graphql } from '@app/__generated__';
import { getErrorCode } from '@app/utils/apollo-errors.util';

const currentUserDocument = graphql(`
  query currentUser {
    currentUser {
      id
      email
      name
      role
    }
  }
`);

export const useCurrentUser = () => {
  const { data } = useSuspenseQuery(currentUserDocument, { fetchPolicy: 'cache-first' });
  return data.currentUser;
};

export const useIsLogged = () => {
  const { error } = useSuspenseQuery(currentUserDocument, { errorPolicy: 'all', fetchPolicy: 'cache-first' });
  if (error && getErrorCode(error) !== 'UNAUTHORIZED') throw error;
  return !error;
};

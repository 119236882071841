import { Flex } from '@app/layout/flex';
import { PageLayout } from '@app/panel/layout/page';
import { graphql } from '@app/__generated__';
import { useSuspenseQuery } from '@apollo/client';
import { GalleryStatus } from '@app/feautres/gallery/containers/gallery-status';
import { Text } from '@app/layout/text';
import { StatusPageWrapper } from './status-page.styles';
import { PageInfo } from '@app/layout/page-info';

const inProgressGalleriesDocument = graphql(`
  query inProgressGalleries {
    inProgressGalleries {
      id
      name
      startsAt
      endsAt
      capturesCount
      type {
        name
      }
    }
  }
`);

export const StatusPage = () => {
  const { data } = useSuspenseQuery(inProgressGalleriesDocument);

  return (
    <PageLayout title="Status">
      {!data.inProgressGalleries.length && <PageInfo>No galleries in progress...</PageInfo>}
      <StatusPageWrapper>
        {data.inProgressGalleries.map((gallery) => (
          <Flex gap={5} key={gallery.id}>
            <Text center size="lg">
              {gallery.name}
            </Text>
            <GalleryStatus galleryId={gallery.id} />
          </Flex>
        ))}
      </StatusPageWrapper>
    </PageLayout>
  );
};
